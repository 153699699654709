import React, { useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

//import Computer from 'assets/broadcast/computer.svg'
import Gedeon from 'assets/broadcast/logo-gedeon.jpg'
import Netty from 'assets/broadcast/logo-netty.png'
import Twimmo from 'assets/broadcast/logo-twimmo.png'

import Immo from 'assets/broadcast/logo-boite-immo.png'
import Immo2 from 'assets/broadcast/logo-boite-immo@2x.png'
import Immo3 from 'assets/broadcast/logo-boite-immo@3x.png'
import Apimo from 'assets/broadcast/logo-apimo.png'
import Apimo2 from 'assets/broadcast/logo-apimo@2x.png'
import Apimo3 from 'assets/broadcast/logo-apimo@3x.png'
import Ubiflow from 'assets/broadcast/logo-ubiflow.png'
import Ubiflow2 from 'assets/broadcast/logo-ubiflow@2x.png'
import Ubiflow3 from 'assets/broadcast/logo-ubiflow@3x.png'
import Email from 'assets/broadcast/email.png'
import Email2 from 'assets/broadcast/email@2x.png'
import Email3 from 'assets/broadcast/email@3x.png'

//import Block from './Block'
import SectionTitle from 'components/sectionTitle'
import AgencyForm from 'components/agencyForm'

const scrollToRef = formRef => {
  formRef.current.scrollIntoView()
}

function Section2(props) {
  const [sticky, setSticky] = useState(true)
  //const [footerPos, setFooterPos] = useState(true)
  //const isMobile = window.innerWidth <= 1025
  //const isBgColored = true
  //console.log('isBgColored', isBgColored)

  const formRef = useRef(null)

  const showForm = () => {
    scrollToRef(formRef)
    console.log(formRef.current.getBoundingClientRect().top)
  }

  return (
    <React.Fragment>
      <Section>
        <SectionTitle
          style={{
            height: 'auto',
            padding: '0 5% 30px 5%',
            margin: '60px auto 40px'
          }}
        >
          Comment <Blue>diffuser gratuitement</Blue> vos annonces ?
        </SectionTitle>
      </Section>

      <Row>
        <SubTitle>
          Activez directement la diffusion sur Arlet via votre outil :
        </SubTitle>
        <FlexContainer>
          <Center>
            <Img loading="lazy" src={Netty} alt="logo Netty" />
          </Center>

          <Center>
            <Img loading="lazy" src={Twimmo} alt="logo Twimmo" />
          </Center>

          <Center>
            <Img loading="lazy" src={Gedeon} alt="logo Gédéon" />
          </Center>

          <Center>
            <Img
              loading="lazy"
              src={Immo}
              srcSet={`${Immo2} 2x, ${Immo3} 3x`}
              alt="logo La Boite Immo"
            />
          </Center>

          <Center>
            <Img
              loading="lazy"
              src={Apimo}
              srcSet={`${Apimo2} 2x, ${Apimo3} 3x`}
              alt="logo Apimo"
            />
          </Center>

          <Center>
            <Img
              loading="lazy"
              src={Ubiflow}
              srcSet={`${Ubiflow2} 2x, ${Ubiflow3} 3x`}
              alt="logo Ubiflow"
            />
          </Center>
        </FlexContainer>
        <ComingSoon>... et plus à venir</ComingSoon>
      </Row>

      <Row2>OU</Row2>

      <Row>
        <FormTitle ref={formRef}>
          Contactez-nous via le formulaire pour commencer à diffuser sur Arlet
        </FormTitle>
        <FormContainer>
          <Cta onClick={() => showForm()}>
            Commencer à diffuser mes annonces
          </Cta>
          <FormBlock
            src={Email}
            srcSet={`${Email2} 2x, ${Email3} 3x`}
            alt="email"
            title="Contactez-nous via le formulaire ci-dessous : "
            className={[showForm && 'show', sticky && 'isSticky']
              .filter(e => !!e)
              .join(' ')}
          >
            <AgencyForm textAreaRows={5} />
          </FormBlock>
        </FormContainer>
      </Row>
    </React.Fragment>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 600px;
  ${media.lessThan('1025px')`
    align-items: center;
    justify-content: flex-start;
  `}
`

const Center = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  ${media.lessThan('1025px')`
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  `}
`

const Section = styled(Center)`
  display: flex;
  width: 100%;
  position: relative;
  ${media.lessThan('1025px')`
  `}
`

const Row = styled(Center)`
  margin: -50px 0px 40px 0px;
  position: relative;
  align-items: center;
  ${media.lessThan('1025px')`
  `}
`

const Row2 = styled(Row)`
  border-radius: 50px;
  height: 80px;
  width: 80px;
  background: #e9f5fa;
  font-weight: bold;
  margin: auto;
  margin-bottom: 70px;
  top: -20px;
  color: ${theme.colors.secondary};
  font-size: 1.5em;
  ${media.lessThan('1025px')`
  justify-content: center;
  `}
`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const ComingSoon = styled.span`
  margin: 10px auto 40px auto;
  display: block;
  text-align: center;
  color: #aaaaaa;
  font-size: 0.9em;
`
const SubTitle = styled.p`
  padding: 0 20px;
  font-size: 1.2em !important;
  font-weight: 500 !important;
  position: relative;
  text-align: center;
  color: ${theme.colors.secondary};
`

const Img = styled.img`
  margin-top: 70px;
  object-fit: contain;
  max-width: 200px;
  min-height: 80px;
  margin: 10px;

  ${media.lessThan('1025px')`
    width: auto;
    margin-top: 20px;
    max-width: 130px;
    min-height: 80px;
    margin: 0px;
  `}

  ${media.lessThan('350px')`
    max-width: 100px;
    margin: 5px 10px;
  `}

  ${media.between('350px', '1025px')`
    max-width: 170px;
  `}
`

const Cta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  flex-shrink: 0;
  z-index: 998;
  // bottom: 5vw;
  bottom: -1vw;
  left: 0;
  right: 0px;
  width: calc(100% - 40px);
  height: 80px;
  background-color: #e84c5c;
  color: #ffffff;
  padding: 0 20px;
  font-weight: 800;
  margin: auto;
  text-align: center;
  //box-shadow: 0 2px 47px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;

  ${media.greaterThan('1025px')`
  display: none;
`}
`

const FormBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-sizing: border-box;
  box-shadow: 0px 2px 87px rgba(33, 146, 204, 0.1);
  padding: 30px 25px;
  z-index: 10000;
  right: 0;
  height: 100vh;
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  width: 100%;
  max-width: 600px;
  border-radius: 15px;

  @media (min-height: 770px) {
    justify-content: center;
  }

  @media (max-height: 769px) {
    justify-content: flex-start;
  }

  ${media.greaterThan('1025px')`
  `}

  ${media.lessThan('1025px')`
      height: 100%;
      width: 95vw;
      z-index: 999;
      padding: 20px 15px 30px;
      margin: 2.5vh 2.5vw;
      border-radius: 20px;
      justify-content: space-between;
      transition: all ease .5s;

    /*
      &.show.isSticky{
        position: fixed !important;
        top: 100% !important;
        visibility: visible !important;
        transition: all ease .5s;
      }
      &.hide{
        position: fixed !important;
        top: 0;
        transition: all ease .5s;
        visibility: visible !important;
      }
      &.isSticky{
        position: fixed !important;
        top: 0 !important;
        height: 100vh !important;
        transition: all ease .5s;
        visibility: visible !important;
        margin: 2.5vh 0 ;
        width: 100vw;
        overflow: scroll;
      }
      */

  `}
`
/*
const WhiteOverlay = styled.div`
  position: fixed;
  height: 55px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  top: 14px;
  border-radius: 20px;
  z-index: 99;

  &.show {
    top: 3%;
    transition: all 0s ease 0s;
    visibility: hidden !important;
  }
  &.hide {
    visibility: visible !important;
    transition: all 0s ease 0.5s;
  }

  ${media.lessThan('812px')`
      top: 19px;
    `}

    ${media.lessThan('768')`
      top: 14px;
    `}

  ${media.greaterThan('1025px')`
    width: 439px;
    &.show, .hide {
      visibility: visible !important;
      top: 0;
    }

  `}
`
*/
const FormTitle = styled.p`
  color: ${theme.colors.secondary};
  width: 80%;
  text-align: center;
  font-size: 1.1em;
  ${media.greaterThan('1025px')`
    margin: 10px auto 19px auto;
  `}
`

const FormContainer = styled.div`
  margin-top: 15px;
  ${media.lessThan('1025px')`
    margin-top: 0;
  `}
`

export default Section2
