import React, { useState, useContext, useEffect } from 'react'
import styled, { css } from 'styled-components/macro'
import { withRouter, NavLink } from 'react-router-dom'
import media from 'styled-media-query'
import { observer } from 'mobx-react-lite'

import menu from 'assets/header/menu@2x.png'
import MobileMenu from 'components/mobileMenu'
import UiStoreContext from 'stores/UiStore'

import Headroom from 'react-headroom'

const Header = observer(({ location: { pathname } }) => {
  const [initialShow, setInitialShow] = useState(true)
  const normalHeader = pathname !== '/' && pathname !== '/broadcast'
  const broadcastheader = ''
  //const broadcastheader = pathname == '/broadcast'
  const { showMobileMenu, setProperty } = useContext(UiStoreContext)

  //Ajout du logo au cookie banner
  const [loaded, error] = useScript('https://consent.cookiebot.com/uc.js')

  // Hook
  let cachedScripts = []
  function useScript(src) {
    // Keeping track of script loaded and error state
    const [state, setState] = useState({
      loaded: false,
      error: false
    })

    useEffect(
      () => {
        // If cachedScripts array already includes src that means another instance ...
        // ... of this hook already loaded this script, so no need to load again.
        if (cachedScripts.includes(src)) {
          setState({
            loaded: true,
            error: false
          })
        } else {
          cachedScripts.push(src)

          // Create script
          let script = document.createElement('script')
          script.src = src
          script.async = true
          script.setAttribute(
            'data-cbid',
            'ea4bd7ed-8906-4815-97b9-c42a63901e9b'
          )
          script.setAttribute('data-blockingmode', 'auto')

          // Script event listener callbacks for load and error
          const onScriptLoad = () => {
            setState({
              loaded: true,
              error: false
            })
          }

          const DOMContentLoaded = () => {
            try {
              const cookieBanner = document.querySelector(
                '#CybotCookiebotDialogBody'
              )
              const cookieLogo = document.createElement('img')
              cookieLogo.src = '/images/logo-arlet-cookies.png'
              cookieLogo.id = 'CookieImg'
              cookieBanner.prepend(cookieLogo)
              document
                .querySelector('#CybotCookiebotDialog')
                .classList.add('load')
            } catch (err) {
              console.error(err)
            }
          }

          const onScriptError = () => {
            // Remove from cachedScripts we can try loading again
            const index = cachedScripts.indexOf(src)
            if (index >= 0) cachedScripts.splice(index, 1)
            script.remove()
            setState({
              loaded: true,
              error: true
            })
            document.querySelector('#CookieImg').remove()
          }

          script.addEventListener('load', onScriptLoad)
          script.addEventListener('error', onScriptError)
          script.addEventListener('DOMContentLoaded', DOMContentLoaded)

          // Add script to document body
          document.body.appendChild(script)
          // Remove event listeners on cleanup
          return () => {
            script.removeEventListener('load', onScriptLoad)
            script.removeEventListener('error', onScriptError)
            script.removeEventListener('DOMContentLoaded', DOMContentLoaded)
          }
        }
      },
      [src] // Only re-run effect if script src changes
    )

    return [state.loaded, state.error]
  }

  return (
    <HeaderMenu broadcastheader={broadcastheader.toString()}>
      <SHeader
        normalHeader={normalHeader}
        showMenu={showMobileMenu}
        broadcastheader={broadcastheader}
      >
        {!showMobileMenu && (
          <React.Fragment>
            <NavLink to="/">
              <Logo />
            </NavLink>

            <SLinks broadcastheader={broadcastheader}>
              <A to="/">
                <HeaderTitle>Télécharger l'application</HeaderTitle>
              </A>
              <HeaderTitle>
                <A to="/broadcast">Diffuser sur Arlet</A>
              </HeaderTitle>
              {process.env?.REACT_APP_PROSPECT_AREA_SHOW === 'true' && (
                <React.Fragment>
                  <HeaderTitle>
                    <A to="/contact">Contactez-nous</A>
                  </HeaderTitle>
                  <A to="/espace-client">Espace client</A>
                </React.Fragment>
              )}
              {process.env?.REACT_APP_PROSPECT_AREA_SHOW !== 'true' && (
                <A to="/contact">Contactez-nous</A>
              )}
            </SLinks>

            <Menu
              broadcastheader={broadcastheader}
              src={menu}
              alt="menu img"
              onClick={() => {
                document.body.classList.add('lock-body')
                setProperty('showMobileMenu', !showMobileMenu)
                if (initialShow) setInitialShow(false)
              }}
            />
          </React.Fragment>
        )}

        <MobileMenu2
          initialShow={initialShow}
          broadcastheader={broadcastheader}
        />
      </SHeader>
    </HeaderMenu>
  )
})

// const broadcastHeaderStyle = {
//   // width: props.showMobileMenu ? '100%' : '70%',
//   width: '70%',
//   zIndex: '999'
// };
//
// const normalHeaderStyle = {
//   width: '100%',
//   zIndex: '999',
// };

const A = styled(NavLink)`
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
const HeaderMenu = styled(Headroom)`
  z-index: 999;
`

const MobileMenu2 = styled(MobileMenu)`
  ${media.lessThan('1300px')`
  display: ${props => (props.broadcastheader ? 'block !important' : '')};
`}
`

const SHeader = styled.header.attrs(props => ({
  padding: props.showMenu ? '0px' : '10px',
  position: props.normalHeader ? 'relative' : 'relative',
  background: props.normalHeader
    ? 'linear-gradient(0.25turn, #1787E0, #175270);'
    : 'transparent'
}))`
  position: ${props => props.position};
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  justify-content: start;
  align-items: center;
  /* background: ${props => props.background}; */
  background: #1787E0;
  padding-top: 16px;
  padding-bottom: 16px;
  z-index: 999;
  width: ${props =>
    props.broadcastheader
      ? 'calc(100% - 454px) !important'
      : '100% !important'};

  ${media.lessThan('1025px')`
    justify-content: center;
    padding-top: ${props => props.padding};
    padding-bottom: ${props => props.padding};
    width: 100% !important
  `}
`

const Logo = styled.div`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('/images/logo-arlet-white.png');
  margin: 0 30px;

  ${media.greaterThan('1025px')`
    height: 60px;
    width: 150px;
  `}


  ${media.lessThan('1439px')`
    height: 60px;
    width: 150px;
    background-size: contain;
  `}

  ${media.lessThan('768px')`
    background-size: contain;
  `}
`

const SLinks = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  position: absolute;
  right: 30px;

  ${media.lessThan('1025px')`
    display: ${props => (props.broadcastheader ? 'none !important' : 'none')};
  `}

  ${media.lessThan('1300px')`
  display: ${props => (props.broadcastheader ? 'none !important' : '')};
  `}

  ${media.greaterThan('1300px')`
  margin-right: ${props => (props.broadcastheader ? '0' : '')};
  display: ${props => (props.broadcastheader ? 'block !important' : '')};

  `}

  ${media.greaterThan('1440px')`
    margin-right: 0;
  `}
`

const Menu = styled.img`
  position: absolute;
  right: 20px;
  width: 30px;
  height: 30px;
  object-fit: contain;
  font-size: 30px;
  color: #ffffff;

  ${media.greaterThan('1025px')`
    display: none;
  `}

  ${media.lessThan('1300px')`
  display: ${props => (props.broadcastheader ? 'flex !important' : '')};
  `}
`

const HeaderTitle = styled.span`
  margin-right: 20px;
`

export default withRouter(Header)
