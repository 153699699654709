import React, { useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

function CGU() {
  useEffect(() => {
    //scroll to top of page
    window.scrollTo(0, 0)

    //cookieBot text
    const script = document.createElement('script')
    script.src = `https://consent.cookiebot.com/ea4bd7ed-8906-4815-97b9-c42a63901e9b/cd.js`
    script.id = 'CookieDeclaration'
    script.async = true
    console.log('-->', document.querySelector('#cookies'))
    // setTimeout(() => {
    document.querySelector('#cookies').appendChild(script)

    // }, 3000)
  }, [])

  return (
    <SPage id="top">
      <STerms>
        <Title>
          CONDITIONS GÉNÉRALES D'UTILISATION - POLITIQUE DE CONFIDENTIALITÉ -
          COOKIES
        </Title>
        <Version>VERSION DU 26.06.2020</Version>
        <SegmentTitle>PRÉAMBULE</SegmentTitle>
        <Text>
          Les Conditions Générales régissent les offres de Produits et/ou
          Services proposées par TREE DIGITAL FACTORY. Elles sont complétées, le
          cas échéant, par les Conditions Particulières. Les Conditions
          Générales et, le cas échéant, les Conditions Particulières,
          constituent le Contrat liant TREE DIGITAL FACTORY à l’Utilisateur
          et/ou Client.
        </Text>
        <Text>
          <b>
            <u>
              Ce dernier s’engage donc à les lire attentivement et reconnaît que
              l’utilisation, la souscription et/ou la Commande des Produits
              et/ou Services de TREE DIGITAL FACTORY implique leur acceptation
              sans restriction ni réserve.
            </u>
          </b>
        </Text>
        <Text>
          TREE DIGITAL FACTORY s'engage à ce que les traitements de données
          personnelles effectués sur le site{' '}
          <a href="https://www.treedigitalfactory.com">
            https://www.treedigitalfactory.com
          </a>{' '}
          soient conformes au règlement général sur la protection des données
          (RGPD) et à la loi Informatique et Libertés.
        </Text>
        <Text>
          Les Conditions Générales et les Conditions Particulières peuvent être
          modifiées à tout moment par TREE DIGITAL FACTORY, afin notamment de se
          conformer à toute évolution éditoriale, commerciale, technique, légale
          ou réglementaire.
        </Text>
        <Text>
          Les nouvelles Conditions Générales et Conditions Particulières seront
          opposables à l’Utilisateur et/ou Client à compter de leur mise en
          ligne sur le Site et appliquées aux utilisations, souscriptions et/ou
          commandes intervenant à compter de cette date. Il est donc conseillé
          de se référer avant toute nouvelle utilisation, souscription ou
          commande, à la version en vigueur des Conditions Générales et/ou des
          Conditions Particulières. Celles-ci sont consultables sur le Site et
          peuvent être demandées au Service Client.
        </Text>
        <Text>
          S’agissant des Contrats en cours, les modifications substantielles des
          Conditions Générales et des Conditions Particulières seront notifiées
          à l’Utilisateur et/ou Client concerné avec un préavis minimum d’un
          mois. A défaut de résiliation dans ce délai, l’Utilisateur et/ou
          Client est réputé accepter les nouvelles conditions contractuelles
          applicables. Les stipulations des Conditions Générales et des
          Conditions Particulières s’appliquent concomitamment à la Politique de
          Confidentialité de TREE DIGITAL FACTORY.
        </Text>
        <Text>
          L’éventuelle tolérance d’un manquement contractuel ne vaut pas
          renonciation de TREE DIGITAL FACTORY à s’en prévaloir. La nullité
          d’une stipulation du Contrat est sans effet sur ses autres clauses.
        </Text>

        <SegmentTitle>1. DÉFINITIONS</SegmentTitle>
        <Text>
          Pour les besoins des présentes Conditions Générales, les termes en
          dont la 1ère lettre est en majuscule ont la signification suivante :
        </Text>
        <Text>
          « TREE DIGITAL FACTORY » désigne TREE DIGITAL FACTORY, Société par
          Actions Simplifiée au capital de 23 584€ dont le siège social est 15
          Avenue Jean Jaurès, 92800 Puteaux immatriculée au RCS de Nanterre sous
          le numéro 524 314 846.
        </Text>
        <Text>
          « Site Internet » : désigne le service électronique interactif édité
          et exploité par TREE DIGITAL FACTORY, accessible notamment à l'adresse{' '}
          <a href="https://www.arlet.immo">www.arlet.immo</a>, depuis lequel
          elle permet l'accès à ses Services.
        </Text>
        <Text>
          « Application » : désigne le service électronique interactif exploité
          par TREE DIGITAL FACTORY, accessible depuis tout terminal disposant
          d'une connexion au réseau Internet, par téléchargement gratuit sur
          l'AppStore ou GooglePlay, après installation, d'accéder aux Services,
          et plus particulièrement ceux proposés par l’application ARLET.
        </Text>
        <Text>
          « Alerte » : désigne le service électronique permettant à un
          Utilisateur de paramétrer une recherche immobilière et d’être notifié
          par le canal de son choix (email, notification push ou Facebook
          Messenger) en cas de nouveaux bien correspondants aux critères qu’il
          aura préalablement défini.
        </Text>
        <Text>
          « Services » : désignent les services fournis par TREE DIGITAL FACTORY
          sur le Site Internet et/ou l'Application et décrits à l'article 4 des
          présentes Conditions Générales, ainsi que leurs extensions sous forme
          d'alerte, de newsletters, et de fonctionnalités du terminal de
          l'Utilisateur, nécessaires à leur fonctionnement.
        </Text>
        <Text>
          « Utilisateur » : désigne toute personne physique qui accède au Site
          Internet et/ou à l'Application dans le cadre d'un usage strictement
          privé.
        </Text>
        <Text>
          « Données » : désignent les données à caractère personnel telles que
          définies à l'article 4 du Règlement (UE) 2016/679 du Parlement
          européen et du Conseil du 27 avril 2016 relatif à la protection des
          personnes physiques à l'égard du traitement des données à caractère
          personnel et à la libre circulation de ces données (ci-après désigné «
          Règlement Général sur la Protection des Données ») qui sont fournies
          par l'Utilisateur à TREE DIGITAL FACTORY ou qui sont recueillies par
          TREE DIGITAL FACTORY à l'occasion de l'utilisation du Site Internet,
          de l'Application et/ou des Services.
        </Text>
        <Text>
          « Partenaires » : désignent les annonceurs diffusant des d'annonces de
          location et/ou de vente de biens immobiliers sur le Site Internet
          et/ou l'Application et ou les partenaires commerciaux avec lesquelles
          TREE DIGITAL FACTORY est en relation.
        </Text>

        <SegmentTitle>
          2. POLITIQUE DE CONFIDENTIALITÉ - PROTECTION DES DONNÉES À CARACTÈRE
          PERSONNEL
        </SegmentTitle>
        <Text>
          TREE DIGITAL FACTORY a adopté une Charte de protection des données
          personnelles des utilisateurs qui énonce quatre principes guidant
          l’utilisation des données personnelles à savoir le respect, la
          transparence, la confidentialité et la sécurité.{' '}
          <a href="https://www.treedigitalfactory.com/wp-content/uploads/2020/02/Charte-Utilisateur-27-fevrier-2020.pdf">
            Lire la charte
          </a>
        </Text>

        <SegmentSubTitle>2.1. DONNÉES À CARACTÈRE PERSONNEL</SegmentSubTitle>
        <Text>
          <b>a) Responsable du traitement</b>
        </Text>
        <Text>
          Le responsable du traitement des Données de l'Utilisateur est TREE
          DIGITAL FACTORY.
        </Text>
        <Text>
          b) Nature des données à caractère personnel de l'Utilisateur
          collectées
        </Text>
        <Text>
          Parmi les Données que TREE DIGITAL FACTORY collecte auprès de
          l’Utilisateur ou de son terminal peuvent notamment figurer :
        </Text>
        <Ul>
          <li>sa civilité,</li>
          <li>son nom et prénom,</li>
          <li>son pays,</li>
          <li>son adresse postale,</li>
          <li>son adresse de courrier électronique (email),</li>
          <li>son numéro de téléphone,</li>
          <li>son consentement pour recevoir des offres commerciales,</li>
          <li>la date de création de son compte,</li>
          <li>ses messages,</li>
          <li>ses critères de recherche,</li>
          <li>
            des informations relatives à son projet immobilier, sa navigation et
            à ses interactions avec les Services et le Site Internet ou
            l’Application (historique de recherches, formulaires, cookies,
            etc.).
          </li>
        </Ul>

        <Text>
          TREE DIGITAL FACTORY collecte les informations fournies par les
          Utilisateurs notamment :
        </Text>
        <Ul>
          <li>lors de la création d’un compte utilisateur,</li>
          <li>
            lors du paramétrage de l’Alerte proposé dans le cadre des Services
            offert par TREE DIGITAL FACTORY,
          </li>
          <li>lors de la saisie et de l’envoi des formulaires de contact,</li>
          <li>
            lors de leurs navigations sur le Site Internet, l’Application, les
            formulaires et/ou tout support de communication édité par TREE
            DIGITAL FACTORY (consultation d’annonces, recherches, etc.),
          </li>
          <li>
            lors de questionnaires ou sondages visant à obtenir des retours de
            l’Utilisateur sur l’utilisation des services proposés sur le Site ou
            l’Application.
          </li>
        </Ul>

        <Text>
          <b>c) Fondement légal du traitement</b>
        </Text>
        <Text>
          Dans le cadre de l’acceptation des Conditions Générales, et en
          application de l’article 6.1 du Règlement Général sur la Protection
          des Données, l’Utilisateur est informé que les différents traitements
          de ses Données évoqués ci-après sont nécessaires (i) à l'exécution et
          à la réalisation des Services proposés par TREE DIGITAL FACTORY et
          dont la fourniture est régie par les présentes Conditions Générales
          constituant le contrat souscrit par l’Utilisateur visant à
          l'accompagner dans le cadre de son projet immobilier ; (ii) ainsi
          qu’aux intérêts légitimes poursuivis par TREE DIGITAL FACTORY
          d’amélioration de ses Services et de compréhension des attentes des
          Utilisateurs, permettant notamment la protection des droits et des
          Données de l’Utilisateur.
        </Text>

        <Text>
          <b>d) Finalité du traitement</b>
        </Text>
        <Text>
          Les Données des Utilisateurs traitées par TREE DIGITAL FACTORY sont
          utilisées pour les finalités suivantes :
        </Text>
        <Ul>
          <li>pour leur permettre de créer un compte,</li>
          <li>
            pour leur fournir les Services disponibles à travers le Site
            Internet et/ou l’Application,
          </li>
          <li>
            pour les contacter par téléphone ou leur envoyer par courrier
            électronique les réponses, les informations diverses ou les annonces
            publiées sur le Site Internet et/ou l’Application par TREE DIGITAL
            FACTORY ou par ses Partenaires, clients de TREE DIGITAL FACTORY,
          </li>
          <li>pour répondre à leurs demandes,</li>
          <li>
            pour établir des statistiques générales sur le trafic de son Site
            Internet et/ou l’Application et des différentes rubriques qu'ils
            contiennent,
          </li>
          <li>
            pour leur envoyer par courrier électronique des newsletters sur
            l'évolution du Site Internet et/ou l’Application et des différentes
            rubriques dudit Site Internet,
          </li>
          <li>
            pour mieux connaître leurs besoins et simplifier leurs navigations
            et/ou pour analyser ou prédire des éléments les concernant comme
            leurs préférences personnelles, intérêts, fiabilité, et leurs
            comportements,
          </li>
          <li>
            pour mener des enquêtes et études de satisfaction afin d’améliorer
            les Services proposés par TREE DIGITAL FACTORY
          </li>
        </Ul>

        <Text>
          Lorsque l’Utilisateur a expressément donné son consentement ses
          données sont également utilisées pour des opérations liées à des
          partenariats commerciaux, réalisées par les Partenaires sous leur
          seule et unique responsabilité :
        </Text>
        <Ul>
          <li>
            Partage, échange ou location de fichiers avec des partenaires
            commerciaux dans le respect de la réglementation en vigueur et des
            exigences de sécurité,
          </li>
          <li>
            Envoi de messages marketing, publicitaires et promotionnels relatifs
            aux produits et services de partenaires par courrier postal,
            e-mails, notifications mobiles, sur les réseaux sociaux ou tout
            autre support.
          </li>
        </Ul>

        <Text>
          <b>
            e) Destinataires des données à caractère personnel de l'Utilisateur
          </b>
        </Text>
        <Text>
          Les Données peuvent être communiquées, dans le respect des lois
          applicables à TREE DIGITAL FACTORY, pour une ou plusieurs des
          finalités décrites à l’Article 2.1 d), aux personnes listées
          ci-dessous:
        </Text>

        <Ul>
          <li>
            au personnel habilité et autorisé de TREE DIGITAL FACTORY pouvant
            être amené à traiter les Données,
          </li>
          <li>
            aux prestataires informatiques de TREE DIGITAL FACTORY chargés
            notamment de fournir un service et des publicités adaptés aux
            Utilisateurs et de mesurer la fréquentation du Site Internet et/ou
            Application conformément aux dispositions de l’article 3.1 des
            présentes,
          </li>
          <li>
            aux autorités administratives ou judiciaires autorisées par la loi
            française,
          </li>
          <li>
            aux partenaires immobiliers, clients de la Société, que les
            Utilisateurs souhaitent expressément et spécifiquement contacter
            afin de gérer leurs demandes (information sur un bien, une agence,
            demande d’estimation d’emprunt et tous services qui concerne une
            démarche de projet immobilier (Assurances, établissement de crédits,
            promoteurs, commercialisateurs, agences immobilières, sociétés de
            travaux, decoration, équipements de la maison, fournisseur d'énergie
            ou internet, …),
          </li>
          <li>
            aux autres partenaires commerciaux de TREE DIGITAL FACTORY lorsque
            les Utilisateurs ont accepté expressément de recevoir les offres
            promotionnelles de leur part.
          </li>
        </Ul>

        <Text>
          <b>
            f) Durée de conservation des données à caractère personnel de
            l'Utilisateur
          </b>
        </Text>
        <Text>
          Les Données sont conservées par TREE DIGITAL FACTORY, dans le respect
          des lois applicables à TREE DIGITAL FACTORY, durant vingt-quatre (24)
          mois à compter de son dernier contact avec TREE DIGITAL FACTORY à
          l’exception de celles dont la conservation par TREE DIGITAL FACTORY
          est nécessaire afin que celle-ci soit en mesure de remplir ses
          obligations légales (p. ex. identification des auteurs de contenus).
        </Text>

        <SegmentSubTitle>
          2.2 DROIT D'OPPOSITION D'ACCÈS, DE RECTIFICATION ET DE SUPPRESSION DE
          L'UTILISATEUR
        </SegmentSubTitle>
        <Text>
          <b>a) Droits de l’Utilisateur</b>
        </Text>
        <Text>
          Conformément aux lois applicables à TREE DIGITAL FACTORY,
          l'Utilisateur a la faculté :
        </Text>
        <Ul>
          <li>
            de s'opposer, à tout moment pour des raisons tenant à sa situation
            personnelle, au traitement de ses Données dans le cadre des Services
            fournis par TREE DIGITAL FACTORY,
          </li>
          <li>
            de s’opposer, à tout moment au traitement des Données à des fins de
            prospection,
          </li>
          <li>
            de s'opposer à la communication des Données à des tiers, d’accéder à
            l'ensemble de ses Données traitées dans le cadre des Services
            fournis par TREE DIGITAL FACTORY,
          </li>
          <li>
            de rectifier, mettre à jour et supprimer ses Données traitées dans
            le cadre des Services fournis par TREE DIGITAL FACTORY, sous réserve
            de justifier de leur identité auprès de TREE DIGITAL FACTORY et
            lorsque cela est techniquement possible, de demander la portabilité
            des seules données à caractère personnel qu’il a fournies à TREE
            DIGITAL FACTORY.
          </li>
        </Ul>

        <Text>
          En cas d’exercice du droit d’opposition par l’Utilisateur, TREE
          DIGITAL FACTORY cessera le traitement de ses Données, sauf en cas de
          motif(s) légitime(s) et impérieux pour le traitement, ou pour assurer
          la constatation, l’exercice ou la défense de ses droits en justice,
          conformément au Règlement Général sur la Protection des Données. Le
          cas échéant, TREE DIGITAL FACTORY informera l’Utilisateur des motifs
          pour lesquels les droits qu’il exerce ne sauraient être satisfaits en
          tout ou partie.
        </Text>

        <Text>
          <b>b) Modalités d'exercice de ses droits par l'Utilisateur</b>
        </Text>
        <Text>
          Pour exercer ses droits, il suffit à l'Utilisateur d'adresser un
          simple courrier à TREE DIGITAL FACTORY à l'adresse indiquée à
          l'article 1.1 ou de contacter TREE DIGITAL FACTORY via l'envoi d'un
          email à l'adresse{' '}
          <a href={'mailto:donneespersonnelles@treedigitalfactory.com'}>
            donneespersonnelles@treedigitalfactory.com
          </a>{' '}
          en prenant soin de joindre un justificatif d’identité à sa demande
          (mentionner ses noms, prénoms, adresse de courrier électronique)
          conformément au Chapitre III du Règlement Général sur la Protection
          des Données.{' '}
        </Text>
        <Text>
          Nous vous informons de l’existence de la liste d'opposition au
          démarchage téléphonique «Bloctel», sur laquelle vous pouvez vous
          inscrire ici :{' '}
          <a href="https://conso.bloctel.fr/">https://conso.bloctel.fr/</a>.
        </Text>

        <SegmentSubTitle>
          2.3 SECURITÉ ET ARCHIVAGE DES DONNÉES À CARACTÈRE PERSONNEL DE
          L'UTILISATEUR
        </SegmentSubTitle>
        <Text>
          TREE DIGITAL FACTORY collecte et traite les Données avec la plus
          grande confidentialité et sécurité, et dans le respect des lois
          applicables à TREE DIGITAL FACTORY.
        </Text>
        <Text>
          TREE DIGITAL FACTORY s'engage à prendre toutes les mesures
          raisonnables nécessaires à la sécurisation et à la protection des
          Données des Utilisateurs de son Site Internet et/ou Application et de
          ses Services, collectées et traités par ses soins (notamment pare-feu,
          contrôles d’accès physiques aux data center, habilitations, etc.).
        </Text>

        <SegmentSubTitle>
          2.4 RÈGLEMENT DES DIFFÉRENDS CONCERNANT LE TRAITEMENT DES DONNÉES
        </SegmentSubTitle>
        <Text>
          En cas de différend entre TREE DIGITAL FACTORY et l’Utilisateur
          concernant le traitement des Données, l’Utilisateur pourra adresser sa
          réclamation au Délégué à la Protection des Données de TREE DIGITAL
          FACTORY en le contactant par simple courrier à l'adresse indiquée à
          l'article 1 ou via l'envoi d'un email à l'adresse{' '}
          <a href={'mailto:donneespersonnelles@treedigitalfactory.com'}>
            donneespersonnelles@treedigitalfactory.com
          </a>
          . TREE DIGITAL FACTORY s’efforcera de trouver une solution
          satisfaisante pour l’Utilisateur, pour assurer le respect de la
          réglementation applicable.
        </Text>
        <Text>
          En l’absence de réponse de TREE DIGITAL FACTORY ou si le différend
          persiste malgré la proposition de TREE DIGITAL FACTORY, l’Utilisateur
          a la possibilité, conformément aux dispositions du Règlement Général
          sur la Protection des Données d’introduire une réclamation auprès de
          la Commission Nationale de l’Informatique et des Libertés ou de
          l’autorité de contrôle de l’État Membre de l’Union Européenne au sein
          duquel l’Utilisateur réside habituellement.
        </Text>

        <SegmentTitle>3. INFORMATION RELATIVES AUX COOKIES</SegmentTitle>
        {/*<Text>
          Arlet utilise les cookies. Les cookies nous permettent de
          personnaliser le contenu et les annonces, d'offrir des fonctionnalités
          relatives aux médias sociaux et d'analyser notre trafic. Nous
          partageons également des informations sur l'utilisation de notre site
          avec nos partenaires de médias sociaux, de publicité et d'analyse, qui
          peuvent combiner celles-ci avec d'autres informations que vous leur
          avez fournies ou qu'ils ont collectées lors de votre utilisation de
          leurs services. Vous consentez à nos cookies si vous continuez à
          utiliser notre site Web.
        </Text>
        <Text>
          Les cookies sont des petits fichiers textes qui peuvent être utilisés
          par les sites Web pour rendre l'expérience utilisateur plus efficace.{' '}
        </Text>
        <Text>
          La loi stipule que nous ne pouvons stocker des cookies sur votre
          appareil que s’ils sont strictement nécessaires au fonctionnement de
          ce site. Pour tous les autres types de cookies, nous avons besoin de
          votre permission.
        </Text>
        <Text>
          Ce site utilise différents types de cookies. Certains cookies sont
          placés par les services tiers qui apparaissent sur nos pages.
        </Text>
        <Text>
          À tout moment, vous pouvez modifier ou retirer votre consentement dès
          la Déclaration relative aux cookies sur notre site Web.
        </Text>
        <Text>
          En savoir plus sur qui nous sommes, comment vous pouvez nous contacter
          et comment nous traitons les données personnelles veuillez voir notre
          Politique confidentialité.
        </Text>
        <Text>
          Veuillez indiquer l'identifiant de votre consentement et la date à
          laquelle vous nous avez contactés concernant votre consentement.
        </Text>*/}
        <Cookie id="cookies"></Cookie>
        {/*<script id="CookieDeclaration" src="https://consent.cookiebot.com/ea4bd7ed-8906-4815-97b9-c42a63901e9b/cd.js" type="text/javascript" async></script>*/}

        <SegmentSubTitle>3.1 MESURES D’AUDIENCE</SegmentSubTitle>
        <Text>
          TREE DIGITAL FACTORY utilise des cookies sur son Site Internet et son
          Application. Les cookies sont des fichiers d'informations installés
          sur votre ordinateur par les sites Internet que vous visitez. Les
          cookies permettent de stocker des informations de navigation, telles
          que vos préférences de navigation ou des informations de profil.
        </Text>
        <Text>
          Au cours de votre navigation sur le Site Internet ou l’Application,
          nos services peuvent envoyer à des sites tiers un certain nombre
          d’éléments reconnus automatiquement : la date et l’heure, l’adresse
          IP, le nom de domaine, le logiciel de navigation et le système
          d’exploitation utilisés (si le navigateur communique cette
          information), l’adresse de la page précédemment visitée (si le
          navigateur la fournit), l’objet demandé et l’état d’exécution de la
          requête.
        </Text>
        <Text>
          Un cookie ne permet en aucun cas de vous identifier et disparaît à
          chaque nettoyage de vos fichiers temporaires. Vous pouvez refuser les
          cookies en utilisant l'onglet « outil » de votre navigateur et en
          utilisant ensuite l'onglet vous permettant de gérer la confidentialité
          ou les préférences de navigation. Pour en savoir plus sur la gestion
          des cookies depuis votre navigateur, nous vous invitons à visiter le
          site de la CNIL et plus particulièrement l’adresse web:{' '}
          <a href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser">
            https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser
          </a>
          .
        </Text>

        <SegmentSubTitle>
          3.2 LISTE DES COOKIES SUR LE SITE INTERNET
        </SegmentSubTitle>

        <Text>
          <b>a) Cookies nécessaires et cookies de préférences</b>
        </Text>

        <Text>
          Les cookies nécessaires contribuent à rendre le Site Internet et
          l’Application utilisable en activant des fonctions de base comme la
          navigation de page et l'accès aux zones sécurisées. Le site Internet
          et l’Application ne peuvent pas fonctionner correctement sans ces
          cookies.
        </Text>
        <Text>
          Les cookies de préférences permettent au Site Internet et à
          l’Application de retenir des informations qui modifient la manière
          dont le Site Internet ou l’Application se comporte ou s’affiche.
        </Text>
        <Text>Liste des cookies :</Text>

        <Text>
          <b>b) Cookies statistiques</b>
        </Text>
        <Text>
          Les cookies statistiques nous aident par la collecte et la
          communication d'informations de manière anonyme (adresses IP
          anonymisées avant la collecte des informations), à comprendre comment
          vous interagissez avec le Site Internet ou l’Application. Si vous ne
          souhaitez pas communiquer vos données personnelles liées à votre
          navigation sur des sites équipés du code de tracking Google Analytics,
          sachez que vous pouvez bloquer ce cookie publicitaire en installant le
          module complémentaire de navigateur pour la désactivation de Google
          Analytics. Cet outil est disponible en téléchargement à l'adresse :{' '}
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout
          </a>
        </Text>

        <Text>
          <b>c) Cookies Marketing</b>
        </Text>
        <Text>
          Les cookies marketing sont utilisés pour effectuer le suivi des
          visiteurs au travers du Site et de l’Application et de partager ces
          informations avec nos partenaires médias sociaux et publicité en
          ligne.
        </Text>
        <Text>
          Ces cookies sont collectés par Facebook (et les marques affiliées
          telles que Instagram, WhatsApp,…), Google (et les marques affiliées
          telles que Adwords, DoubleClick, Analytics, etc…), Twitter, Tree
          Digital Factory.
        </Text>

        <SegmentTitle>
          4. SERVICES PROPOSÉS : DESCRIPTION ET DISPONIBILITÉ
        </SegmentTitle>
        <Text>
          Le Site et/ou l’Application permet de faciliter les démarches de
          l’Utilisateur dans sa recherche d'achat neuf ou ancien et/ou de
          location d'un bien immobilier à titre personnel que ça soit dans une
          démarche pour y habiter ou pour investir.{' '}
        </Text>
        <Text>Il bénéficie gratuitement des Services suivants :</Text>
        <Ul>
          <li>
            un service de recherche, visualisation d'offres de biens
            immobiliers,
          </li>
          <li>
            un service d'accès à des conseils pratiques destinés à accompagner
            l'Utilisateur dans son projet immobilier,
          </li>
          <li>
            un service de mise en relation avec les Partenaires immobiliers de
            la Société,
          </li>
          <li>
            un service permettant à l'Utilisateur de rechercher des informations
            sur sa capacité d’emprunt, des solutions de financement … Il pourra
            ainsi être mis en contact auprès de différents établissements de
            crédit, d’assurance, de réduction d'impôts ou tous partenaires
            économiques de la Société qui pourraient intervenir dans le
            processus d’un projet immobilier (promoteurs, commercialisateurs,
            agences immobilières, sociétés de travaux, décoration, équipements
            de la maison, fournisseur d'énergie ou internet …),
          </li>
        </Ul>
        <Text>
          TREE DIGITAL FACTORY fait ses meilleurs efforts afin de rendre ses
          Services disponibles 24 heures sur 24 et 7 jours sur 7, indépendamment
          des opérations de maintenance. TREE DIGITAL FACTORY se réserve la
          possibilité de modifier, interrompre, à tout moment, temporairement ou
          de manière permanente tout ou partie des Services sans information
          préalable des Utilisateurs et sans droit à indemnités.
        </Text>
        <Text>
          TREE DIGITAL FACTORY fait ses meilleurs efforts pour assurer la
          continuité des Services ; cependant, compte tenu de la complexité et
          des circonstances spécifiques à l'activité particulière d'hébergeur,
          TREE DIGITAL FACTORY ne peut être tenue qu'à une obligation de moyens
          au titre des présentes Conditions Générales.
        </Text>
        <Text>
          En conséquence TREE DIGITAL FACTORY ne saurait être tenue responsable
          des difficultés ou impossibilités d'accès, de la lenteur de la
          connexion ou tout autre problème technique dus à des circonstances
          et/ou à des intermédiaires techniques extérieurs à TREE DIGITAL
          FACTORY.
        </Text>

        <SegmentTitle>
          5. QUALITÉ ET DISPONIBILITÉ DES CONTENUS PROPOSÉS
        </SegmentTitle>
        <Text>
          TREE DIGITAL FACTORY fait les meilleurs efforts pour assurer
          l'exactitude de l'ensemble des informations mises à la disposition des
          visiteurs sur le Site et l’Application. Les informations contenues
          dans le descriptif des biens à vendre ou à louer sont fournies par les
          partenaires identifiées et agissant en conformité avec la législation.
          Chaque agence ou mandataire est juridiquement et financièrement
          indépendant et vous communique ces informations sous sa propre
          responsabilité. La mise à jour des annonces est le fait de ces
          partenaires, qui sont tenues de veiller à la qualité de l'information
          qu'elles diffusent au public.
        </Text>
      </STerms>
    </SPage>
  )
}
const SPage = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  .CookieDeclarationDialogText {
    margin: 14px 0px !important;
  }
`

const STerms = styled.div`
  width: 80%;
  max-width: 800px;
  border-radius: 25px;
  box-shadow: 0 2px 87px 0 rgba(85, 163, 181, 0.1);
  background-color: #ffffff;
  margin: 0 auto;
  padding: 40px 40px;

  ${media.lessThan('1026px')`
          width: 100%;
          padding: 20px;
        `}
`
const SegmentTitle = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  color: #114b62;
  margin: 30px auto 0px auto;
  text-transform: uppercase;
`

const SegmentSubTitle = styled.div`
  font-size: 1.3em;
  font-weight: 600;
  color: #114b62;
  margin: 30px auto 0px auto;
`

const Version = styled.div`
  font-size: 22px;
  font-style: italic;
  color: #9e9e9e;
  text-align: center;
  margin: 20px auto 40px auto;
`

const Title = styled(SegmentTitle)`
  text-align: center;
  font-weight: 800;
  font-size: 2em;
`

const Text = styled.p`
  font-family: NunitoSans;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #114b62;
  text-align: justify;

  & > a,
  a:visited {
    text-decoration: underline !important;
    color: #1787e0;
  }
`
const Cookie = styled.div`
  &#cookies {
    color: #114b62;
  }

  & div > div {
    border-radius: 10px;
    border-color: #e9e9e9;
  }
  & > a,
  a:visited {
    text-decoration: underline !important;
    color: #1787e0;
  }
`

const Ul = styled.ul`
  font-family: NunitoSans;
  font-size: 16px;
  color: #114b62;
`

export default CGU
