import React, { useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

function Terms() {
  useEffect(() => {
    //scroll to top of page
    window.scrollTo(0, 0)
  }, [])

  return (
    <SPage id="top">
      <STerms>
        <Title>MENTIONS LÉGALES</Title>
        <Version>VERSION DU 10.08.2021</Version>
        <Text>
          L’application Arlet est éditée par TREE DIGITAL FACTORY
          <SegmentTitle>Éditeur</SegmentTitle>
          TREE DIGITAL FACTORY
          <br />
          SAS au capital de 23 584€
          <br />
          Siège social : 15 rue Jean Jaurès, 92800 PUTEAUX
          <br />
          RCS Nanterre : 524 314 846
          <br />
          Téléphone +33 (0) 1 84 17 44 95
          <br />
          Directeur de la publication : Philippe Lourenço
          <br />
          <SegmentTitle>Hébergement</SegmentTitle>
          AMAZON WEB SERVICE EMEA SARL
          <br />
          38 Avenue John F. Kennedy, <br />
          L-1855 Luxembourg
          <br />
          <SegmentTitle>Objectif et qualité des contenus</SegmentTitle>
          Cette application a pour objectif d’informer le grand public, les
          professionnels et les médias. TREE DIGITAL FACTORY s’efforce de
          fournir une information de qualité et vérifiée, toutefois si une
          information semble inexacte ou contient une erreur typographique, vous
          pouvez le{' '}
          <a href={'https://arlet.immo/contact'}>
            signaler à l’administrateur de l'application
          </a>
          .
          <SegmentTitle>
            Utilisation des marques de TREE DIGITAL FACTORY
          </SegmentTitle>
          Les marques verbales et visuelles (logos) TREE DIGITAL FACTORY et
          ARLET IMMO sont protégés.
          <br />
          Leur utilisation sans autorisation écrite de TREE DIGITAL FACTORY, sur
          tout support, à des fins de valorisation de produits ou de services,
          notamment à des fins commerciales, est interdite sous peine de
          poursuites pénales et civiles.
          <br />
          Elle est admise à des fins strictement pédagogiques (présentation des
          actions de TREE DIGITAL FACTORY, etc.). Pour toute autre
          réutilisation,{' '}
          <a href={'https://arlet.immo/contact'}>nous contacter</a>.
          <SegmentTitle>Textes et articles</SegmentTitle>
          Les textes disponibles sur le site sont des contenus pédagogiques
          élaborés par TREE DIGITAL FACTORY qui sont mis à disposition aux
          conditions suivantes :
          <Ul>
            <li>
              - Attribution : vous devez créditer les contenus. Vous devez
              indiquer ces informations par tous les moyens raisonnables, sans
              toutefois suggérer que TREE DIGITAL FACTORY vous soutient ou
              soutient la façon dont vous avez utilisé ses contenus.
            </li>
            <li>- Pas de modifications.</li>
          </Ul>
          <SegmentTitle>Images, vidéos et iconographies</SegmentTitle>
          Les images disponibles sur le site comprennent les photographies,
          captures écran et illustrations.
          <br />
          Sous réserve des droits de propriété intellectuelle de tiers, les
          images qui sont la propriété exclusive de l’éditeur sont, sauf mention
          contraire, mises à disposition aux conditions suivantes:
          <Ul>
            <li>
              - Attribution : vous devez créditer les contenus. Vous devez
              indiquer ces informations par tous les moyens raisonnables, sans
              toutefois suggérer que TREE DIGITAL FACTORY vous soutient ou
              soutient la façon dont vous avez utilisé ses contenus.
            </li>
            <li>
              - Pas d’utilisation commerciale : Vous n'êtes pas autorisé à faire
              un usage commercial de ces contenus, tout ou partie du matériel
              les composant.
            </li>
            <li>- Pas de modifications.</li>
          </Ul>
        </Text>
      </STerms>
    </SPage>
  )
}

const SPage = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0px;
`

const STerms = styled.div`
  width: 80%;
  max-width: 800px;
  border-radius: 25px;
  box-shadow: 0 2px 87px 0 rgba(85, 163, 181, 0.1);
  background-color: #ffffff;
  margin: 0 auto;
  padding: 20px 40px;

  ${media.lessThan('1026px')`
    width: 100%;
    padding: 20px;
  `}
`
const SegmentTitle = styled.div`
  font-size: 1.5em;
  font-weight: 700;
  color: #114b62;
  margin: 30px auto 0px auto;
  text-transform: uppercase;
`
/*
const SousSegmentTitle = styled.div`
  font-size: 1.3em;
  font-weight: 600;
  color: #114b62;
  margin: 30px auto 0px auto;
`
*/
const Version = styled.div`
  font-size: 22px;
  font-style: italic;
  color: #9e9e9e;
  text-align: center;
  margin: 20px auto 40px auto;
`

const Title = styled(SegmentTitle)`
  text-align: center;
  font-weight: 800;
  font-size: 2em;
`

const Text = styled.p`
  font-family: NunitoSans;
  font-size: 1em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #114b62;
  text-align: justify;

  & > a,
  a:visited {
    text-decoration: underline !important;
    color: #1787e0;
  }
`
/*
const Cookie = styled.div`
  &#cookies {
    color: #114b62;
  }

  & div > div {
    border-radius: 10px;
    border-color: #e9e9e9;
  }
  & > a,
  a:visited {
    text-decoration: underline !important;
    color: #1787e0;
  }
`
*/
const Ul = styled.ul`
  list-style: none;
  font-family: NunitoSans;
  font-size: 16px;
  color: #114b62;
  padding-left: 15px;

  & li {
    padding-bottom: 5px;
  }
`

export default Terms
