import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

import BadgeLogement from 'assets/neuf/icn-logement-perso.png'
import BadgePrix from 'assets/neuf/icn-prix-negociables.png'
import BadgeImpots from 'assets/neuf/icn-economies-impots.png'
import BadgeNormes from 'assets/neuf/icn-energetique-moderne.png'
import BadgeGaranties from 'assets/neuf/icn-garanties-solides.png'

import BadgeBlock from '../broadcast/BadgeBlock'
import SectionTitle from 'components/sectionTitle'

function Section1() {
  return (
    <Wrapper>
      <Title>
        Pourquoi <Blue>acheter</Blue> dans le <Blue>neuf</Blue> ?
      </Title>

      <Section>
        <BadgeBlock
          img={BadgeLogement}
          text="Des logements modernes et personnalisables à votre image"
        />

        <BadgeBlock
          img={BadgePrix}
          text="Des prix négociables et des frais de notaires souvent offerts"
        />

        <BadgeBlock
          img={BadgeImpots}
          text="Des économies d’impôts allant jusqu’à 63 000€ sur 12 ans"
        />

        <BadgeBlock
          img={BadgeNormes}
          text="Aux normes énergétiques et  équipements collectifs dernière génération"
        />

        <BadgeBlock
          img={BadgeGaranties}
          text="Des garanties solides pendant 10 ans"
        />
      </Section>
    </Wrapper>
  )
}

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const Title = styled(SectionTitle)`
  margin: 50px auto 0px auto !important;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  position: relative;
  background: #f3f9fd;
  margin: 0px auto 50px auto;
  ${media.between('767px', '1025px')`
    flex-direction: row;
  `}
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  position: relative;
  margin: 0 auto 50px auto;
  max-width: 800px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  ${media.lessThan('1025px')`
    flex-direction: column;
    align-items: center;
    margin: 5px auto;
    padding-bottom: 30px;
  `}
`

export default Section1
