import React, { useContext } from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import { observer } from 'mobx-react-lite'

import IosBadge from 'components/iosBadge'
import AndroidBadge from 'components/androidBadge'
import UiStoreContext from 'stores/UiStore'

const Banner = observer(() => {
  const { showMobileMenu } = useContext(UiStoreContext)

  if (showMobileMenu) return null

  return (
    <SBanner>
      <Wrapper>
        <Text>
          Téléchargez l’application Arlet pour contacter le vendeur et accéder à
          toutes les fonctionnalités.
        </Text>
        <Badges>
          <IosBadge />
          <AndroidBadge />
        </Badges>
      </Wrapper>
    </SBanner>
  )
})

const SBanner = styled.div`
  position: sticky;
  top: 0;
  z-index: 1101;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  // box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.3);
  background-image: linear-gradient(to right, #1787e0, #1787e0);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 9px 15%;
  height: 140px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);

  ${media.lessThan('1025px')`
    flex-direction: column;
    padding: 0px 10px;
  `}
`

const Text = styled.div`
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  flex: 2;
  ${media.lessThan('1025px')`
    font-size: 1em;
    text-align: center;
    padding-top: 20px;
  `}
`

const Badges = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px;

  ${media.lessThan('1025px')`
    display: flex;
    flex-direction: row;
  `}
`

export default Banner
