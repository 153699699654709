import axios from 'axios'

export const getFaktorToken = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/admin/faktor/get-token`
    )

    if (res.data && res.data.token) {
      return res.data.token
    }
  } catch (err) {
    throw new Error('cannot retrieve token: ' + err.message)
  }

  throw new Error('cannot retrieve token: no token found')
}
