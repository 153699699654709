import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

import BG from 'assets/presse/bg-presse.jpg'

import Icn1 from 'assets/presse/icn-email.png'
import Logo1 from 'assets/presse/preview-logo-blanc.png'
import Logo2 from 'assets/presse/preview-logo-bleu.png'
import Logo3 from 'assets/presse/preview-logo-bleu-a.png'
import Logo4 from 'assets/presse/preview-logo-blanc-a.png'

import AppScreen1 from 'assets/presse/arlet-app-screen1.png'
import AppScreen2 from 'assets/presse/arlet-app-screen2.png'
import AppScreen3 from 'assets/presse/arlet-app-screen3.png'

function Press() {
  return (
    <div>
      <Hero>
        <Title>Presse</Title>
      </Hero>

      <Wrapper>
        <Subtitle>A propos</Subtitle>
        <Text>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac diam nec
          quis metus amet aliquam at semper praesent. Lorem ipsum dolor sit
          amet, consectetur adipiscing elit. Ac diam nec quis metus amet aliquam
          at semper praesent.
        </Text>
        <Subtitle>Contact</Subtitle>
        <ContactCard>
          <Row>
            <Photo src="" />
          </Row>
          <Row>
            <Text style={{ fontWeight: 700 }}>Charles D’Hueppe</Text>
            <Text>Chief Product Officer</Text>
            <Text style={{ color: theme.colors.primary }}>
              <Icn src={Icn1} />
              <a href="mailto:contact@arlet.immo">contact@arlet.immo</a>
            </Text>
          </Row>
        </ContactCard>
        <Subtitle>Communiqué de presse</Subtitle>
        <CommuniqueCard>
          <RowContainer>
            <CommuniqueTitle>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit Lorem
              ipsum dolor sit amet.
            </CommuniqueTitle>
            <Row>
              <Date>24/09/2021</Date>
            </Row>
          </RowContainer>
          <Text style={{ alignItems: 'left' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac diam nec
            quis metus amet aliquam at semper praesent. Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Ac diam nec quis metus amet
            aliquam at semper praesent.
          </Text>

          <Cta>Lire le communiqué</Cta>
        </CommuniqueCard>
        <Subtitle style={{ display: 'inline-block', width: 'fit-content' }}>
          Logos
        </Subtitle>{' '}
        <Download href="/images/logos-arlet.zip">Tout télécharger</Download>
        <FlexContainer>
          <LogoContainer>
            <a href="/images/logo-arlet-bleu-fond-blanc.png" target="_blank">
              <Logo src={Logo1} />
              <Legende>Logo Arlet bleu sur fond blanc</Legende>
            </a>
          </LogoContainer>
          <LogoContainer>
            <a href="/images/logo-arlet-blanc-fond-bleu.png" target="_blank">
              <Logo src={Logo2} />
              <Legende>Logo Arlet blanc sur fond bleu</Legende>
            </a>
          </LogoContainer>
          <LogoContainer>
            <a
              href="/images/logo-arlet-bleu-fond-transparent.png"
              target="_blank"
            >
              <Logo src={Logo3} />
              <Legende>Logo Arlet bleu sur fond transparent</Legende>
            </a>
          </LogoContainer>
          <LogoContainer>
            <a
              href="/images/logo-arlet-blanc-fond-transparent.png"
              target="_blank"
            >
              <Logo src={Logo4} />
              <Legende>Logo Arlet blanc sur fond transparent</Legende>
            </a>
          </LogoContainer>
        </FlexContainer>
        <Subtitle style={{ display: 'inline-block', width: 'fit-content' }}>
          Images
        </Subtitle>{' '}
        <Download href="#">Tout télécharger</Download>
        <ImgContainer>
          <a href="/images/arlet-app-screen1.png" target="_blank">
            <AppScreen src={AppScreen1} />
          </a>
          <a href="/images/arlet-app-screen2.png" target="_blank">
            <AppScreen src={AppScreen2} />
          </a>
          <a href="/images/arlet-app-screen3.png" target="_blank">
            <AppScreen src={AppScreen3} />
          </a>
        </ImgContainer>
        <Subtitle>Ils parlent de nous</Subtitle>
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: auto;
  padding: 30px;

  ${media.lessThan('1025px')`
  width: calc(100% - 40px);
  padding: 20px;
  `}
}

`
const Hero = styled.div`
  background-image: url(${BG});
  background-position: center;
  height: 300px;
  display: flex;
  align-items: center;

  ${media.lessThan('1025px')`
    height: 200px;
  `}
`

const Title = styled.h1`
  font-size: 3em;
  font-weight: 900;
  line-height: 1;
  color: #ffffff
  padding: 20px;
  width: 100vw;
  text-align: left;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  opacity: 0.9;
  position: relative;
  width: calc(86% - 454px);
  text-align: center;
  margin: 0 auto;
  max-width: 630px;

  ${media.lessThan('1025px')`
    font-size: 1.5em;
    line-height: 1em;
    width: calc(100% - 40px);
    text-align: center;
    left: 0%;
    margin: 0 auto;
  `}

  /* < iPhone X */
  @media (max-width: 1025px) and (max-height: 812px) {
    top: 15vh;
  }

  /* < iPhone 6 */
  @media (max-width: 1025px) and (max-height: 620px) {
    top: 5vh;
  }

  ${media.greaterThan('1025px')`
  `}
`

const Subtitle = styled.h2`
  font-size: 1em;
  font-weight: 800;
  line-height: 1;
  color: ${theme.colors.secondary};
  padding: 1em 0 .5em;
  width: 100vw;
  position: relative;
  width: calc(86% - 454px);
  max-width: 630px;
  margin: 30px 0 15px 0;
  }
  ${media.lessThan('1025px')`
    font-size: 1.1em;
    line-height: 1.2em;
    padding-top: 0;
    width: calc(100% - 1em);
    margin-bottom: 15px;
    padding: 1em 0 .5em;
  `}

  /* < iPhone X */
  @media (max-width: 1025px) and (max-height: 812px) {
      top: 15vh;
   }

    /* < iPhone 6 */
    @media (max-width: 1025px) and (max-height: 620px) {
        top: 2vh;
     }

`

const Text = styled.p`
  font-size: 1em;
  text-align: justify;
  display: flex;
  align-items: center;
  color: ${theme.colors.secondary};
  margin: 0;
  width: 100%;
  line-height: 1em;
  font-weight: 500;
  ${media.lessThan('1026px')`

  `}
`
/*
const Blue = styled.span`
  color: ${theme.colors.primary};
`
*/
const ContactCard = styled.div`
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(150, 170, 180, 0.2);
  border-radius: 18px;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;

  & p {
    margin-top: 10px;
  }

  ${media.lessThan('1026px')`
  width: calc(100vw - 100px);
`}
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
`

const Photo = styled.div`
  background-color: grey;
  background-image: url();
  width: 70px;
  height: 70px;
  border-radius: 100px;
  margin-right: 20px;
`

const Icn = styled.img`
  width: 20px;
  margin-right: 7px;
`

const Download = styled.a`
  font-size: 0.9em;
  font-weight: 700;
  color: ${theme.colors.primary} !important;
  display: inline-block;
  margin-left: 10px;
`

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  ${media.lessThan('1026px')`
      justify-content: center;
  `}
`

const LogoContainer = styled.div`
  max-width: 170px;
  text-align: center;
  margin-right: 20px;
`

const ImgContainer = styled.div``

const Logo = styled.img`
  width: 100%;
  max-width: 170px;
  height: auto;
`

const Legende = styled.p`
  text-decoration: underline;
  color: grey;
  font-size: 0.9em;
  line-height: 120%;
`

const AppScreen = styled.img`
  width: 100%;
  max-width: 200px;
  height: auto;
  display: inline-block;
  margin-right: 20px;

  ${media.lessThan('1026px')`
      margin-bottom: 20px;
  `}
`

const RowContainer = styled(FlexContainer)`
  justify-content: space-between;
  margin-bottom: 15px;
`

const Date = styled(Text)`
  color: #979797;
  font-weight: 600;
  font-size: 0.9em;
`

const CommuniqueCard = styled(ContactCard)`
  max-width: 500px;
  color: ${theme.colors.secondary};
  flex-direction: column;
`

const CommuniqueTitle = styled(Row)`
  font-weight: 700;
  line-height: 1em;
  width: 70%;
`

const Cta = styled.a`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  z-index: 998;
  width: auto;
  height: 45px;
  background-color: #1787e0;
  color: #ffffff;
  padding: 0 26px;
  font-weight: 700;
  margin: auto;
  text-align: center;
  cursor: pointer;
  border-radius: 40px;
  margin: 20px 0 0 0;
`

export default Press
