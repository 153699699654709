import { observable, action, decorate } from 'mobx'
import { createContext } from 'react'

class UiStore {
  showMobileMenu = false

  setProperty(key, value) {
    this[key] = value
  }
}

decorate(UiStore, {
  showMobileMenu: observable,
  setProperty: action.bound
})

export default createContext(new UiStore())
