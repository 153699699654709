import currency from 'currency.js'

export const uid = (length = 5) => {
  let uid = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i++)
    uid += possible.charAt(Math.floor(Math.random() * possible.length))

  return uid
}

export const euro = price => {
  try {
    return `${currency(price, {
      formatWithSymbol: false,
      separator: ' ',
      decimal: ',',
      precision: 0
    }).format()} €`
  } catch (err) {
    console.warn(err)
    return ''
  }
}

export const sliceText = ({ text, maxLength = 50 }) => {
  try {
    return text.slice(0, maxLength)
  } catch (err) {
    console.warn(err)
    return text
  }
}
