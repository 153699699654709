import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { contactUs } from 'services/contact'
import * as validators from 'utils/validators'
import { useToasts } from 'react-toast-notifications'

import { useInput, usePhoneInput } from 'hooks/input.hooks'
import Input from 'components/input'
import Button from 'components/button'

function ContactForm({ textAreaRows }) {
  const [disabled, setDisabled] = useState(false)

  const { value: name, onChange: onChangeName, reset: resetName } = useInput('')

  const {
    value: company,
    onChange: onChangeCompany,
    reset: resetCompany
  } = useInput('')

  const { value: email, onChange: onChangeEmail, reset: resetEmail } = useInput(
    ''
  )

  const {
    value: phoneNumber,
    onChange: onChangePhoneNumber,
    reset: resetPhoneNumber
  } = usePhoneInput('')

  const {
    value: message,
    onChange: onChangeMessage,
    reset: resetMessage
  } = useInput('')

  const { addToast } = useToasts()
  useEffect(() => {
    if (
      !validators.isNameValid(name) ||
      !validators.isEmailValid(email) ||
      !validators.isContactMessageValid(message) ||
      (phoneNumber.number && phoneNumber.error)
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [name, email, message, phoneNumber.number, phoneNumber.error])

  const send = async () => {
    const data = {
      name,
      company,
      email,
      phoneNumber: phoneNumber.number,
      message
    }

    try {
      await contactUs(data)
      addToast('Votre message a été envoyé', {
        appearance: 'success',
        autoDismiss: true
      })
      resetName()
      resetCompany()
      resetEmail()
      resetPhoneNumber()
      resetMessage()
    } catch (err) {
      console.error(err)
      addToast('Oups, une erreur est survenue ! Veuillez réessayer', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <React.Fragment>
      <FormCenter>
        <Input
          label="Nom *"
          placeholder="Ex: Jean Michel Dupont"
          name="name"
          required={true}
          error={name && !validators.isNameValid(name)}
          onChange={onChangeName}
        />
      </FormCenter>

      <FormCenter>
        <Input
          label="Entreprise (falcultatif)"
          placeholder="Ex: Banque populaire"
          name="company"
          value={company}
          onChange={onChangeCompany}
        />
      </FormCenter>

      <FormCenter>
        <Input
          label="Email *"
          placeholder="Ex: jm.dupont@gmail.com"
          name="email"
          value={email}
          error={email && !validators.isEmailValid(email)}
          required={true}
          onChange={onChangeEmail}
        />
      </FormCenter>

      <FormCenter>
        <Input
          label="Téléphone (facultatif)"
          placeholder="Ex: 06 05 04 03 02"
          name="phoneNumber"
          value={phoneNumber.number}
          error={phoneNumber.error}
          onChange={onChangePhoneNumber}
        />
      </FormCenter>

      <FormCenter style={{ height: 120, width: '100%' }}>
        <Input
          type="textArea"
          rows={textAreaRows}
          label="Message *"
          placeholder="Votre message..."
          name="message"
          value={message}
          error={message && !validators.isContactMessageValid(message)}
          required={true}
          onChange={onChangeMessage}
          style={{ padding: '7px 9px' }}
        />
      </FormCenter>

      <FormCenter style={{ margin: 'auto' }}>
        <Button disabled={disabled} onClick={send}>
          Envoyer
        </Button>
      </FormCenter>
    </React.Fragment>
  )
}

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const FormCenter = styled(Center)`
  padding-bottom: 34px;
  &:last-child {
    padding-bottom: 0;
  }
  ${media.lessThan('1025px')`
    padding-bottom: 25px;
    align-items: center;
    width: 100%;
  `}

  ${media.greaterThan('1025px')`
    width: 50%;
  `}
`

export default ContactForm
