import React from 'react'
import styled from 'styled-components/macro'
//import media from 'styled-media-query'
import theme from 'utils/theme'
import { numberTwoDigits } from 'utils/string'

function Ref({ data, createdAtOnly }) {
  const created_at = data.timestamps.created_at
    ? new Date(data.timestamps.created_at)
    : null
  const month = numberTwoDigits(created_at.getUTCMonth() + 1) //months from 1-12
  const day = numberTwoDigits(created_at.getUTCDate())
  const year = created_at.getUTCFullYear()
  if (createdAtOnly) {
    return <Text>{!!created_at && `Publié le ${day}/${month}/${year}`}</Text>
  }
  return (
    <Text>
      {!!created_at && `Publié le ${day}/${month}/${year}`}
      {!!created_at && !!data.identity.customer_reference && ` - `}
      {!!data.identity.customer_reference &&
        `Ref : ${data.identity.customer_reference}`}
    </Text>
  )
}

const Text = styled.div`
  margin-top: 8px;
  font-size: 1em;
  line-height: 22px;
  color: ${theme.colors.brownGrey};
`

export default Ref
