import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

function SectionName({ children }) {
  return <Name>{children}</Name>
}

const Name = styled.div`
  font-size: 1.5em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  margin-bottom: 20px;

  ${media.lessThan('1025px')`
    font-size: 1.2em;
    margin-bottom: 14px;
  `}
`

export default SectionName
