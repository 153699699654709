import theme from 'utils/theme'
import styled from 'styled-components'
import React from 'react'
import media from 'styled-media-query'
import { NavLink } from 'react-router-dom'

import Button from 'components/button'
import imageM3 from 'assets/not-found/not-found@3x.png'
import imageM2 from 'assets/not-found/not-found@2x.png'
import imageM from 'assets/not-found/not-found.png'

function NotFound() {
  return (
    <Page>
      <Container>
        <Img
          loading="lazy"
          src={imageM}
          srcSet={`${imageM2} 2x, ${imageM3} 3x`}
        />
        <Title>Oops ! Cette page n’existe pas ou n’est plus accessible.</Title>
        <NavLink to="/">
          <Button>Retour à l’accueil</Button>
        </NavLink>
      </Container>
    </Page>
  )
}

export default NotFound

const Page = styled.div`
  padding: 100px 0px;
  color: ${theme.colors.black};
`

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 200px;
`

const Title = styled.p`
  font-size: 23px;
  font-weight: 900;
  color: #114b62;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
`

const Img = styled.img`
  ${media.lessThan('1025px')`
    width: 100%;
  `}
`
