export const isNameValid = value =>
  value.length >= 3 && /^[a-z ,.'-éèëêaàâäiïîôöûüù]+$/i.test(value)

export const isEmailValid = value =>
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
    value
  )

export const isPasswordValid = value => value.length >= 6
export const isContactMessageValid = value => value.length >= 2

export const isInputTextValid = value => value.length >= 5
