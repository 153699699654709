import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

import Simple from 'assets/home/badge-simple.png'
import Precis from 'assets/home/badge-precis.png'
import Rapide from 'assets/home/badge-rapide.png'
import SectionTitle from 'components/sectionTitle'

import theme from 'utils/theme'

function Section1() {
  return (
    <Section>
      <MainTitle>
        Trouvez <Blue>rapidement</Blue> votre logement
      </MainTitle>

      <Row>
        <Block>
          <Img loading="lazy" src={Simple} />
          <Title>Gain de temps</Title>
          <Text>
            Paramétrez rapidement une alerte qui surveille en permanence pour
            vous les offres immobilières.
          </Text>
        </Block>

        <Block>
          <Img loading="lazy" src={Precis} />
          <Title>Critères précis</Title>
          <Text>
            Recevez seulement des annonces qui correspondent à vos critères.
          </Text>
        </Block>

        <Block>
          <Img loading="lazy" src={Rapide} />
          <Title>Temps-réel</Title>
          <Text>
            Recevez en temps-réel les annonces sur les plateformes de votre
            choix.
          </Text>
        </Block>
      </Row>
    </Section>
  )
}

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const Section = styled.div`
  margin: 60px auto 0;
  background: ${theme.colors.light_primary};
  padding: 30px;

  ${media.lessThan('1170px')`
    margin: 30px auto 10px auto;
    padding: 49px 5px 0 5px;
  `}
`

const Row = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  flex-direction: row;
  border-radius: 25px;
  background-color: ${theme.colors.light_primary};

  ${media.lessThan('1170px')`
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    padding: 0px 20px;
    width: auto;
    margin-top: 30px;
  `}

  ${media.greaterThan('1170px')`
    flex-direction: row;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
`}
`

const Block = styled.div`
  position: relative;
  width: 350px;
  height: auto;
  text-align: center;
  padding: 0px 45px 0px 45px;
  margin-bottom: 0;
  margin: 0 20px;

${media.between('1170px', '1439px')`

`}

  ${media.lessThan('1170px')`
    width: auto;
    max-width: 300px;
    border-radius: 25px;
    padding: 0px 25px 35px 25px;
    margin-bottom: 56px;
    border-radius: 1em;
    margin: 30px 0;
  `}

  ${media.greaterThan('1170px')`
      margin: 30px 20px 20px 20px;
  `}
`

const MainTitle = styled(SectionTitle)`
  margin: 0 !important;
`

const Img = styled.img`
  position: relative;
  top: -35px;
  width: auto;
  height: 148px;
  object-fit: contain;
  margin-bottom: 20px;
  ${media.lessThan('1170px')`
      margin-bottom: 0;
  `}
`

const Title = styled.div`
  font-size: 1.1em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  margin-bottom: 15px;
  margin-top: -28px;

  ${media.lessThan('1170px')`
    font-size: 1em;
    margin: -20px ​0 10px;
  `}
`

const Text = styled.div`
  font-size: 1em;
  color: ${theme.colors.secondary};
  ${media.lessThan('1170px')`
  font-weight: 500;
  `}
`

export default Section1
