import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

function ProgramTitle({ title = 'PROGRAMME IMMOBILLIER' }) {
  return <Name>{title}</Name>
}

const Name = styled.div`
  font-size: 1.5em;
  font-weight: 500;
  color: ${theme.colors.secondary};

  ${media.lessThan('1025px')`
    font-size: 1.2em;
  `}
`

export default ProgramTitle
