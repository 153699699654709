import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

function Text({ children, grey }) {
  return <SText grey={grey}>{children}</SText>
}

const SText = styled.div`
  font-size: 1em;
  color: ${props => (props.grey ? '#9E9E9E' : '#114b62')};
  font-style: ${props => (props.grey ? 'italic' : 'normal')};

  ${media.lessThan('1025px')`
    font-size: 1em;
  `}
`

export default Text
