import React from 'react'
//import styled from 'styled-components/macro'
//import media from 'styled-media-query'

//import { sliceText } from 'utils'
//import Icon from 'components/icon'
import Section from './Section'
import SectionName from './SectionName'
import Text from './Text'

function Description({ shortText, longText }) {
  //const [showMore, setShowMore] = useState(false)
  // const text = showMore
  //   ? longText
  //   : `${sliceText({ text: shortText, maxLength: 250 })}...`

  return (
    <Section>
      <SectionName>Description du bien</SectionName>

      <Text>{longText}</Text>

      {/* {!showMore && <Fading />}

      <Center>
        <SeeMore show={showMore} onClick={() => setShowMore(!showMore)}>
          Voir{' '}
          {showMore ? (
            <React.Fragment>
              moins <Icon name="angle-up-1" />
            </React.Fragment>
          ) : (
            <React.Fragment>
              plus <Icon name="angle-down-1" />
            </React.Fragment>
          )}
        </SeeMore>
      </Center>*/}
    </Section>
  )
}
/*
const Center = styled.div`
  display: flex;
  justify-content: center;
`

const Fading = styled.div`
  position: relative;
  height: 27px;
  top: -27px;
  width: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.62),
    #ffffff
  );
`

const SeeMore = styled.div.attrs(props => ({
  marginTop: props.show ? '20px' : '0px'
}))`
  margin-top: ${props => props.marginTop};
  font-size: 20px;
  font-weight: bold;
  color: #379d7e;

  &:hover {
    cursor: pointer;
  }

  ${media.lessThan('1025px')`
    font-size: 14px;
  `}
`
*/
export default Description
