import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
//import { path } from 'ramda'
import media from 'styled-media-query'
import theme from 'utils/theme'

//import { uid } from 'utils'
import Section from './Section'
import SectionName from './SectionName'
import Text from './Text'

const labels = Object.freeze(['A', 'B', 'C', 'D', 'E', 'F', 'G'])

const performanceValues = [25, 70, 121, 191, 281, 391, 501]
const performanceRange = Object.freeze([
  { value: 1, color: '#0adb9d' },
  { value: 2, color: '#61f127' },
  { value: 3, color: '#cbfa04' },
  { value: 4, color: '#f8dd17' },
  { value: 5, color: '#fdac00' },
  { value: 6, color: '#fc712f' },
  { value: 7, color: '#d94654' }
])

const gasValues = [3, 8, 15, 28, 46, 68, 100]
const gasRange = Object.freeze([
  { value: 1, color: '#fdeafe' },
  { value: 2, color: '#ffddfd' },
  { value: 3, color: '#f9befd' },
  { value: 4, color: '#fb7eff' },
  { value: 5, color: '#f653fc' },
  { value: 6, color: '#e328f4' },
  { value: 7, color: '#b416b9' }
])

function RangeColor({ range, index, value, label }) {
  return (
    <SRange>
      {range.map(tick => {
        let indiceValue = ''
        let color = 'inherit'
        if (tick.value === index) {
          return (
            <Column key={tick.value}>
              <SelectedValue color={tick.color}>
                {labels[index - 1]}
              </SelectedValue>
              <SelectedUnit>
                <SelectedUnitValue>{value}</SelectedUnitValue>
                <SelectedUnitLabel>{label}</SelectedUnitLabel>
              </SelectedUnit>
            </Column>
          )
        } else if (tick.value === 1) {
          indiceValue = labels[0]
          color = '#5f5b5b'
        } else if (tick.value === 7) {
          indiceValue = labels[6]
          color = '#fff'
        }

        return (
          <Indice
            key={`${tick.value}-selected`}
            backgroundColor={tick.color}
            color={color}
          >
            {indiceValue}
          </Indice>
        )
      })}
    </SRange>
  )
}

const cleanGrade = grade => {
  if (!grade || grade > 7) {
    return undefined
  }
  return grade
}

function ConsumptionChart({ index }) {
  return (
    <Row>
      <Text>Diagnostic de performance énergétique</Text>
      {index ? (
        <RangeColor
          range={performanceRange}
          index={index}
          value={performanceValues[index - 1]}
          label="kWh/m².an"
        />
      ) : (
        'Non communiqué'
      )}
    </Row>
  )
}

function GasEmissionChart({ index }) {
  return (
    <Row>
      <Text>Indice d’émission de gaz à effet de serre</Text>
      {index ? (
        <RangeColor
          range={gasRange}
          index={index}
          value={gasValues[index - 1]}
          label="kgeqCO2/m².an"
        />
      ) : (
        'Non communiqué'
      )}
    </Row>
  )
}

function Energy({ data, isOld }) {
  const performance = cleanGrade(data.performance)
  const greenhouse_gas_emission_footprint = cleanGrade(
    data.greenhouse_gas_emission_footprint
  )

  return (
    <Section>
      <SectionName>Diagnostics énergétiques</SectionName>

      {!performance && !greenhouse_gas_emission_footprint ? (
        <Text grey>
          {isOld ? 'Information non communiquée' : 'Non soumis DPE /GES'}
        </Text>
      ) : (
        <Fragment>
          <ConsumptionChart index={performance} />
          <GasEmissionChart index={greenhouse_gas_emission_footprint} />
        </Fragment>
      )}
    </Section>
  )
}

const Row = styled.div`
  margin-top: 10px;
  color: #114b62;
`

const SRange = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 40px 0px;
`

const Indice = styled.div.attrs(props => ({
  first: props.first ? '3px' : '0px',
  last: props.last ? '3px' : '0px'
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 38px;
  margin-right: -7px;
  background-color: ${props => props.backgroundColor};
  border-top-left-radius: ${props => props.first};
  border-bottom-left-radius: ${props => props.first};
  border-top-right-radius: ${props => props.last};
  border-bottom-right-radius: ${props => props.last};
  color: ${props => props.color};
  font-size: 17px;

  ${media.lessThan('1025px')`
    width: 38px;
    height: 27px;
    font-size: 13px;
  `}
`

const Column = styled.div`
  position: relative;
  top: -17px;
  margin-right: -7px;
  width: 70px;

  ${media.lessThan('1025px')`
    width: 50px;
  `}
`

const SelectedUnit = styled.div`
  margin-top: 14px;
  color: ${theme.colors.secondary};
  font-size: 16px;
  text-align: center;
  width: 150px;
  margin-left: -40px;

  ${media.lessThan('1025px')`
    margin-left: 0px;
  `}
`

const SelectedUnitValue = styled.div`
  font-size: 25px;
  line-height: 24px;
  font-weight: ${theme.fontWeight.extraBold};
  font-style: italic;
`
const SelectedUnitLabel = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: ${theme.fontWeight.regular};
`

const SelectedValue = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
  background-color: ${props => props.color};
  font-size: 20px;
  font-weight: bold;
  color: #5f5b5b;

  ${media.lessThan('1025px')`
    width: 50px;
    height: 50px;
    font-size: 15px;
    top: -11px;
  `}
`

export default Energy
