export const getMediaHash = tenant => {
  if (!tenant) {
    return ''
  }

  const media = (tenant.medias || []).find(m => m.type === 6 && !!m.hash)
  return media ? media.hash : ''
}

/* https://kanal.cleever.io/v1/images/x48,q100,b255|255|255/default_logo_hash/tenant_logo_dash.png */
export const getTenantImage = hit => {
  const hash =
    getMediaHash(hit.specs[0].tenant) ||
    getMediaHash(hit.specs[0].owner)

  return hash
    ? `${process.env.REACT_APP_KANAL_BASE_URL}/images/x48,q100,b255|255|255/${process.env.REACT_APP_TENANT_DEFAULT_BASE64}/${hash}.png`
    : ''
}

export const getOwnerImage = hit => {
  const hash = getMediaHash(hit.specs[0].owner) ||
      getMediaHash(hit.specs[0].entity) ||
      getMediaHash(hit.specs[0].tenant)

  return hash
    ? `${process.env.REACT_APP_KANAL_BASE_URL}/images/x48,q100,b255|255|255/${process.env.REACT_APP_TENANT_DEFAULT_BASE64}/${hash}.png`
    : ''
}

export const extractUrlsFromMedias = medias => {
  const supportedFormat = ['jpg', 'png']
  return medias
    .filter(media => supportedFormat.includes(media.format))
    .map(media => getKanalImage(media))
}

export const getKanalImage = media => {
  if (
    media.source ===
    'https://www.le-partenaire.fr/img_annonce/no-img-annonce.png'
  ) {
    return process.env.REACT_APP_IMAGE_NOT_FOUND_URL
  }
  return `${process.env.REACT_APP_KANAL_BASE_URL}/images/500x/${process.env.REACT_APP_IMAGE_NOT_FOUND_BASE64}/${media.hash}.jpg`
}
