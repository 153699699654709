import React from 'react'
import styled from 'styled-components'
//import media from 'styled-media-query'
import theme from 'utils/theme'
import logo3 from 'assets/logo/arlet@3x.png'
import logo2 from 'assets/logo/arlet@2x.png'
import logo from 'assets/logo/arlet.png'
import AndroidBadge from 'components/androidBadge'
import IosBadge from 'components/iosBadge'

function DesktopView() {
  return (
    <DesktopContainer>
      <Logo
        size={105}
        src={logo}
        srcSet={`${logo2} 2x, ${logo3} 3x`}
        alt="Arlet logo"
      />
      <Title>
        Oops ! Cette page n’est accessible que depuis l’application.{' '}
      </Title>
      <TextDesktop>
        Ouvrez cette page depuis votre mobile ou téléchargez l’application pour
        accéder à toutes les fonctionnalités.
      </TextDesktop>
      <div>
        <SIosBadge>
          <IosBadge black={true} />
        </SIosBadge>
        <AndroidBadge black={true} />
      </div>
    </DesktopContainer>
  )
}

function MobileView() {
  return (
    <MobileContainer>
      <Logo src={logo} srcSet={`${logo2} 2x, ${logo3} 3x`} alt="Paper logo" />
      <Text>Vous êtes en train d’être redirigé vers le store…</Text>
    </MobileContainer>
  )
}

function RedirectAppStore() {
  let OS = 'desktop'
  if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
    OS = 'android'
    window.location.href =
      'https://play.google.com/store/apps/details?id=com.treedigitalfactory.paper.prod'
  }
  if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
    OS = 'ios'
    window.location.href =
      'https://apps.apple.com/fr/app/paper-immobilier/id1466622175'
  }
  return OS === 'desktop' ? (
    <Page>
      <DesktopView />
    </Page>
  ) : (
    <MobileView />
  )
}

export default RedirectAppStore

const Page = styled.div`
  padding: 100px 0px;
  color: ${theme.colors.black};
`

const Text = styled.div`
  text-align: center;
`

const DesktopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 200px;
  max-width: 630px;
  margin: auto;
`

const MobileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0px 30px;
  height: calc(100vh - 72px - 75px);
  position: absolute;
  top: 72px;
  left: 0;
  right: 0;
  color: ${theme.colors.secondary};
  font-size: 16px;
  font-weight: bold;
  line-height: 23px;
`

const Logo = styled.img`
  width: ${props => props.size || '75'}px;
  height: ${props => props.size || '75'}px;
  margin: 0 auto;
  margin-bottom: 20px;
`

const Title = styled.p`
  font-size: 30px;
  font-weight: 900;
  color: #114b62;
  text-align: center;
`

const TextDesktop = styled.p`
  font-size: 23px;
  font-weight: 600;
  color: #114b62;
  line-height: 1.22;
  text-align: center;
`

const SIosBadge = styled.span`
  margin-right: 25px;
`
