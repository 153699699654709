import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
//import Device from 'constants'

import AndroidBadge from 'components/androidBadge'
import IosBadge from 'components/iosBadge'

import theme from 'utils/theme'

import videoHeader from 'assets/home/arlet-pulse.mp4'

function Intro() {
  return (
    <STopSection>
      <Column>
        <LeftContent>
          <HomeTitle>
            <Blue>Arlet</Blue>, l’<Blue>alerte immobilière</Blue> en{' '}
            <Blue>avant-première</Blue>
          </HomeTitle>

          <SIntro>
            <ul style={{ paddingLeft: 0 }}>
              <li>
                <Emoji role="img" aria-label="Check1">
                  ✅
                </Emoji>
                <span>Votre alerte prête en 2min</span>
              </li>
              <li>
                <Emoji role="img" aria-label="Check2">
                  ✅
                </Emoji>
                <span>Recevez vos annonces en temps réel</span>
              </li>
              <li>
                <Emoji role="img" aria-label="Check3">
                  ✅
                </Emoji>
                <span>
                  Achat (neuf / ancien) & Location sur toute la France
                </span>
              </li>
            </ul>
          </SIntro>

          <BadgeContainer>
            <SIosBadge>
              <IosBadge black />
            </SIosBadge>
            <SAndroidBadge>
              <AndroidBadge black />
            </SAndroidBadge>
          </BadgeContainer>
        </LeftContent>

        <SIphone>
          <Video autoPlay loop>
            <source src={videoHeader} type="video/mp4" />
          </Video>
        </SIphone>

        <HomeTitleMobile>
          <Blue>Arlet</Blue>, l’<Blue>alerte immobilière</Blue> pour{' '}
          <Blue>acheteurs</Blue> et
          <Blue> locataires</Blue> exigeants
        </HomeTitleMobile>
      </Column>

      <ProBanner>
        Vous êtes un professionnel de l’immobilier ?<br />
        <Diffuser href="/broadcast">
          Diffusez sur Arlet gratuitement >{' '}
        </Diffuser>
      </ProBanner>
    </STopSection>
  )
}

const Video = styled.video`
  width: 500px;

${media.lessThan('768px')`
  width: 90vw;
  margin: 30px auto 0px;
`}

${media.between('769px', '1168px')`

`}

${media.between('1169px', '1439px')`

`}

${media.between('1440px', '1919px')`

`}

${media.greaterThan('1920px')`
  width: 580px;
`}

`
/*
const circleAnim = keyframes`
0% {
  transform: scale(.1);
  opacity: .4;
}
45% {
  transform: scale(7);
  opacity : 0;
}
100% {
  opacity : 0;
}
`

const circleAnim2 = keyframes`
0% {
  transform: scale(.1);
  opacity: .4;
}
45% {
  transform: scale(5);
  opacity : 0;
}
100% {
  opacity : 0;
}
`
*/
/*
const Circle = styled.div`
  animation: ${circleAnim} 5s linear infinite;
  border-radius: 200em;
  background: #1787e0;
  position: relative;
  top: -330px;
  z-index: -1;
  left: 30%;
  width: 100px;
  height: 100px;
  transform: translateX(-50%);

  ${media.lessThan('768px')`
    width: 50px;
    height: 50px;
    left: 45%;
    transform: translateX(-50%);
    top: -160px;
  `}

  ${media.between('768px', '1168px')`
  width: 80px;
  top: -200px;
  height: 80px;
  left: 33%;
  `}

  ${media.between('1168px', '1439px')`
  width: 80px;
  top: -200px;
  height: 80px;
  left: 33%;
  `}

  ${media.between('1440px', '1441px')`
  width: 80px;
  top: -290px;
  height: 80px;
  left: 30%;
  `}

`
*/
/*
const Circle2 = styled(Circle)`
  animation: ${circleAnim2} 5s linear infinite;
  top: -420px;
  width: 100px;
  height: 100px;
  left: 31%;

  ${media.lessThan('768px')`
    left: 45%;
    top: -205px;
    width: 43px;
    height: 43px;
  `}

  ${media.between('768px', '1168px')`
    left: 35%;
    top: -274px;
    width: 70px;
    height: 70px;
  `}

  ${media.between('1168px', '1439px')`
  left: 35%;
  top: -274px;
  width: 70px;
  height: 70px;
  `}

  ${media.between('1440px', '1441px')`
  left: 35%;
  top: -274px;
  width: 70px;
  height: 70px;
  `}
`*/

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const Column = styled.div`
  width: 100%;
  margin:auto;
  display: flex;
  justify-content: center;
  height: 70vh;
  flex-direction: row;


  ${media.lessThan('1170px')`
  height: auto;
  flex-direction: column-reverse;
  `}


  ${media.between('768px', '1439px')`
      max-width: 500px;
      height: auto;
  `}

  ${media.greaterThan('1169px')`
    flex-direction: row;
    max-height: 620px;
    min-height: 570px;
  `}

  ${media.greaterThan('1440px')`
    flex-direction: row;
    max-height: 650px;
  `}

  ${media.greaterThan('1920px')`
    height: 80vh;
    flex-direction: row;
    max-height: 600px;
  `}

`

const STopSection = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',

  '::before': {
    height: '100px'
  }
})

const LeftContent = styled.div`
  max-width: 600px;
  align-self: center;

  ${media.greaterThan('1169px')`
      margin-right: 30px;
      align-self: auto;
  `}

  ${media.greaterThan('1441px')`
        position: relative;
        margin-right: 40px;
        top: 10%;
    `}

    ${media.greaterThan('1169px')`
      margin-right: 0px;
      `}

`

const BadgeContainer = styled.div`
  display: flex;
  align-item: center;
  justify-content: flex-start;

  ${media.lessThan('768px')`
    flex-direction: column;
    text-align: center;
    justify-content: center;
  `}
  ${media.lessThan('1440px')`
    justify-content: center;
    align-items: center;

  `}
`

const SIosBadge = styled.div`
  padding: 10px;
  transition: all 0.2s ease;
  display: flex;
  &:hover {
    cursor: pointer;
    transform: translateY(-8px);
    transform: scale(1.1);
  }

  ${media.lessThan('768px')`
    padding: 0 10px;
  `}
`
const SAndroidBadge = styled.div`
  padding: 10px;
  transition: all 0.2s ease;
  display: flex;
  &:hover {
    cursor: pointer;
    transform: translateY(-8px);
    transform: scale(1.1);
  }
  ${media.lessThan('768px')`
    padding: 0 10px;
  `}
`
/*
const Bold = styled.span`
  font-weight: bold;
`

const Bolder = styled.span`
  font-weight: bolder;
  display: block;
`
*/
const HomeTitle = styled.div`
  color: ${theme.colors.secondary};
  font-size: 3em;
  font-weight: 800;
  line-height: 1em;
  margin-bottom: 40px;
    ${media.lessThan('1170px')`
      display: none;
      max-width: 800px;
      text-align: center;
      margin-bottom: 70px;
      text-align: center !important;
      margin: 2em auto .5em;
      padding: 0 30px;
      max-width: 500px !important;

    `}

  ${media.greaterThan('1169px')`
    font-size: 2.9em;
    line-height: 1.1em;
    margin-top: 10%;
  `}

  ${media.between('768px', '1168px')`
    font-size: 2.2em;
    margin-bottom: 30px;
    margin-top: 70px;
  `}


  ${media.between('1169px', '1440px')`
    font-size: 2.2em;
    margin-bottom: 60px;
    margin-top: 2.5em;
  `}

`

const HomeTitleMobile = styled(HomeTitle)`
  display: block !important;
  ${media.between('1025px', '1440px')`
    margin: 40px auto 30px;
  `}

  ${media.lessThan('768px')`
    height: auto;
    font-size: 1.5em;
    line-height: 1em;
    margin-bottom: 0;
    padding: 0 20px;
    text-align: center;
    margin: 2em auto 0;
  `}

  ${media.greaterThan('1169px')`
    display: none !important;
  `}

`

const Emoji = styled.span`
  margin-right: 15px;
`

const SIntro = styled.div`
  font-family: NunitoSans;
  font-size: .9em;
  line-height: 1.2;
  color: ${theme.colors.secondary};
  margin-bottom: 40px;
  height: auto;

  ${media.lessThan('1170px')`
  font-size: 1em;
  text-align: left;
  padding: 0 20px;
  margin: 0em auto 2em;
  `}
  ${media.between('1170px', '1439px')`
  font-size: 1em;
    padding: 0;
    text-align: left;
    margin: 0em auto 2em;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  `}
  ${media.greaterThan('1170px')`
  font-size: 1em;
  `}

  & li{
    margin: 10px;
    display: flex;
  }

  &li:before{
    content:'✅';
  }
`

const SIphone = styled.div`
  align-self: center;
  z-index: 0;

  ${media.greaterThan('1920px')`
    height: 44vh;
  `}
`
/*
const SIphoneImg = styled.img`

${media.lessThan('768px')`
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  margin: 70px auto 0px;
`}
${media.between('768px', '1170px')`
    max-height: 450px;
    margin: 20px auto -60px;
    position: relative;
    top: 10px;
`}
${media.between('1170px', '1440px')`
  max-height: 450px;
  margin: 0px auto 30px;
  margin-top: 150px;
  position: relative;
`}

${media.between('1441px', '1920px')`
  max-height: 440px;
  margin-top: 240px;
`}
${media.greaterThan('1920px')`
  max-height: 560px;
  margin-top: 0;
  height: 39vh;
`}
`
*/
const ProBanner = styled.div`
  text-align: center;
  color: ${theme.colors.secondary};
  font-size: 1.1em;

  ${media.lessThan('1170px')`
    top: 1.5em;
    position: relative;
    margin-bottom: 40px;
  `}
  ${media.greaterThan('1170px')`
    margin-top: 4em;
  `}

  ${media.between('1169px', '1440px')`
    margin-top: 0em;
  `}

`

const Diffuser = styled.a`
  color: #e84c5c !important;
  font-weight: bold;
  text-decoration: underline;
  margin-top: 10px;
`

export default Intro
