import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import theme from 'utils/theme'

import { Link } from 'react-router-dom'
//import Blue from 'assets/home/blue-rectangle.png'
import IcnPro from 'assets/home/icn-professionnel.png'
import IcnParticuliers from 'assets/home/icn-particulier.png'
import ArrowPro from 'assets/home/arrow-blue.png'
import ArrowPart from 'assets/home/arrow-purple.png'
import BgSection from 'assets/home/bg-visibilite.jpg'

function Section6() {
  //function goToBroadcast() {}

  return (
    <React.Fragment>
      <Section>
        <LeftColumn>
          <LeftTitle Diffusez>
            Donnez plus de visibilité à vos annonces immobilières
          </LeftTitle>
          <LeftText>
            Vous êtes un professionnel de l'immobilier ? Développez tout le
            potentiel de vos annonces immobilières et trouvez des contacts
            rapidement.
          </LeftText>
        </LeftColumn>

        <BlocksContainer>
          <Block first>
            <AlignTop>
              <Free blue>Gratuit</Free>
              <Icn src={IcnPro} />
              <Title first>Professionnels</Title>
            </AlignTop>
            <AlignBottom>
              <Text first>
                Diffusez gratuitement vos annonces immobilières.
              </Text>
            </AlignBottom>
            <AlignBottom>
              <Center>
                <Link to="/broadcast">
                  <Button color="#51A3EE">
                    En savoir plus <BtnIcn src={ArrowPro} />
                  </Button>
                </Link>
              </Center>
            </AlignBottom>
          </Block>

          <Block>
            <AlignTop>
              <Free>Gratuit</Free>
              <Icn src={IcnParticuliers} />
              <Title>Particuliers</Title>
            </AlignTop>

            <AlignBottom>
              <Text disabled>
                Vendez ou louez rapidement votre logement… C’est gratuit !
              </Text>
            </AlignBottom>

            <AlignBottom>
              <Center>
                <Button color="#B099FD">
                  En savoir plus (prochainement) <BtnIcn src={ArrowPart} />
                </Button>
              </Center>
            </AlignBottom>
          </Block>
        </BlocksContainer>
      </Section>
    </React.Fragment>
  )
}

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: left;
  justify-content: center;
  max-width: 550px;
  margin: auto 11% auto 11%;

  ${media.lessThan('768px')`
    font-size: .6;
    margin:  auto 5% auto 5%;
  `}
  ${media.between('768px', '1170px')`
    margin: auto;
  `}
`

const LeftTitle = styled.div`
  max-width: 600px;
  position: relative;
  font-size: 1.8em;
  font-weight: 800;
  text-align: left;
  color: ${theme.colors.white};
  z-index: 0;
  height: auto;
  margin-bottom: 30px;
  ${media.lessThan('1170px')`
  font-size: 1.4em;
    line-height: 1.2EM;
    padding: auto 20px;
    margin:  40px 5% auto 5%;
    text-align: center;
  `}
`

const LeftText = styled.div`
  color: ${theme.colors.white};
  font-size: 1em;
  text-align: left;

  ${media.lessThan('1025px')`
    margin: 30px auto;
    text-align: center;
  `}
`

const Section = styled.div`
  width: 100%;
  background-image: url(${BgSection});
  display: flex;
  flex-direction: row;

  ${media.lessThan('1170px')`
    flex-direction: column;
    align-items: center;


  `}
`

const Center = styled.div`
  position: relative;
  display: flex;
`

const BlocksContainer = styled(Center)`
  margin-top: 50px;
  flex-direction: column;
  justify-content: ;
  margin-right: 6em;
  ${media.lessThan('1025px')`
    flex-direction: column;
    margin-top: 10px;
    padding: 0px 20px;
    margin-right: 0;
    margin-bottom: 30px;
  `}
`

const AlignBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: baseline;
`

const AlignTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Block = styled.div.attrs(props => ({
  // background: props.first
  //   ? 'linear-gradient(to bottom, #B4E4FF, #7BD0FF)'
  //   : 'linear-gradient(to bottom, #87FFE2, #4FE9BA)',
  // marginRight: props.first ? '0' : '0'
}))`
  background: #ffffff;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 1.5em;
  background: ${props => props.background};
  padding: 30px 30px;
  margin-right: ${props => props.marginRight};
  margin-bottom: 50px;
  -webkit-box-shadow: 0px 10px 45px 0px rgba(101, 187, 206, 0.15);
  -moz-box-shadow: 0px 10px 45px 0px rgba(101, 187, 206, 0.15);
  box-shadow: 0px 10px 45px 0px rgba(101, 187, 206, 0.15);


  ${media.lessThan('768px')`
  width: 90%;
  max-width: 300px;
  margin: 10px 0;
  padding: 20px;
  min-height: 310px;
  `}
  ${media.between('769px', '1170px')`
    width: 360px;
  `}
  ${media.between('1170px', '1440px')`
    width: 360px;
  `}
  ${media.greaterThan('1440px')`
    width: 487px;
  `}


`

const Free = styled.div.attrs(props => ({
  background: props.first
    ? 'linear-gradient(to bottom, #B4E4FF, #7BD0FF)'
    : 'linear-gradient(to bottom, #87FFE2, #4FE9BA)',
  marginRight: props.first ? '0' : '0'
}))`
  position: absolute;
  top: 15px;
  right: 15px;
  width: 90px;
  height: 24px;
  text-align: center;
  font-size: 1.1em;
  font-weight: 600;
  text-transform: uppercase;
  padding: 7px 15px;
  border-radius: 50px;
`

const Icn = styled.img`
  margin: 0 0 10px 0;
  width: 60px;
  height: 60px;
`
/*
const FreeText = styled.div`
  position: relative;
  padding: 5px;
`
*/
const Title = styled.div.attrs(props => ({
  color: props.first ? '#0E85B8' : '#0C8F88'
}))`
  font-size: 1.7em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  text-align: left;
  margin: 4px 0;
  ${media.lessThan('1044px')`
    font-size: 1.3em;
  `}
`

const Text = styled.div`
  font-size: 1em;
  font-family: 'NunitoSans';
  font-weight: 600;
  text-align: left;
  color: #979797;
`

const Button = styled.button.attrs(props => ({}))`
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  border-radius: 100px;
  font-size: 1em;
  font-family: 'NunitoSans';
  font-weight: 700;
  padding: 0;
  color: ${props => props.color};
  transition: all 0.2s ease;
  background-color: transparent;
  align-items: center;
  margin-top: 20px;
  &:hover {
    cursor: pointer;
    transform: translateY(-8px);
    transform: scale(1.1);
  }
`

const BtnIcn = styled.img`
  max-width: 15px;
  height: auto;
  margin-left: 10px;
`

export default Section6
