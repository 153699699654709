import React, { Fragment } from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import media from 'styled-media-query'
//import theme from 'utils/theme'

import logo from 'assets/logo/Logo-Arlet-White.png'
//import arrow from 'assets/home/arrow-red.png'
import AndroidBadge from 'components/androidBadge'
import IosBadge from 'components/iosBadge'

import ComplementFooter from './ComplementFooter'

function Footer({ location: { pathname } }) {
  const showNewsletterSection =
    pathname === '/' ||
    pathname === '/broadcast' ||
    pathname === '/contact' ||
    pathname.includes('/list/')

  const hideDownloadSection = pathname.includes('/espace-client')

  const broadcastFooter = ''
  const isFooterHidden = pathname !== '/'

  return (
    <SFooter
      id="footer"
      broadcastFooter={broadcastFooter}
      isFooterHidden={isFooterHidden}
    >
      {showNewsletterSection && (
        <Fragment>
          <Newsletter isFooterHidden={isFooterHidden} />
        </Fragment>
      )}

      <Container>
        <Logo src={logo} />

        <LinksColumns>
          <P>Liens</P>
          <A href="/">Accueil</A>
          <A href="/broadcast">Diffuser sur Arlet</A>
          {process.env?.REACT_APP_PROSPECT_AREA_SHOW === 'true' && (
            <A href="/espace-client">Espace Client</A>
          )}
          {/*<A href="">Presse</A>
          <A href="">Foire aux questions</A>*/}
          <A href="/cgu-confidentialite">CGU - Confidentialité</A>
          <A href="/mentions-legales">Mentions Légales</A>
        </LinksColumns>

        <LinksColumns>
          <P>Contact</P>
          <A href="/contact">Contactez-nous</A>
          <A
            href="https://www.facebook.com/Arletimmo-110129214772339"
            target="_blank"
          >
            Facebook
          </A>
          {/*<A href="">Instagram</A>*/}
        </LinksColumns>

        {!hideDownloadSection && (
          <LinksColumns>
            <P>Téléchargez Arlet</P>
            <BadgesContainer style={{ marginBottom: 0 }}>
              <Ios />
              <Android />
            </BadgesContainer>
          </LinksColumns>
        )}
      </Container>

      <SLowSection>
        <PaperRights>Arlet &#169; 2021 - Tous droits réservés</PaperRights>
      </SLowSection>
    </SFooter>
  )
}

const SFooter = styled.footer`
  flex-shrink: 0;
  z-index: 997;
  ${props =>
    `width : ${props.broadcastFooter ? 'calc(100% - 454px)' : '100%'};`}

    ${props => `position : ${props.fixedFooter ? 'fixed' : 'relative'};`}
    ${props => `bottom : ${props.fixedFooter ? '0' : ''};`}

  ${media.lessThan('1025px')`
    width: 100%;
  `}
`

const Newsletter = styled(ComplementFooter)`
  ${props => `width : ${props.isFooterHidden ? 'none' : 'none'};`}
`

const Logo = styled.img`
  width: 150px;
  position: relative;
  top: 47px;
  ${media.lessThan('1025px')`
    top: 27px;
    width: 100px;
    margin: 0 0 2em 0;
  `}
`

const Container = styled.div`
  display: flex;
  height: auto;
  font-size: 1em;
  font-weight: normal;
  display: flex;
  align-items: baseline;
  justify-content: space-around;
  background: #1787e0;
  padding: 10px 50px 20px;

  ${media.lessThan('1044px')`
  flex-direction: column;
  text-align: center;
  align-items: center;
`}
`

const LinksColumns = styled.div`
  display: flex;
  flex-direction: column;

  ${media.lessThan('1044px')`
    width: 100%;

`}
`
/*
const Bull = styled.span`
  ${media.lessThan('1044px')`
  display: none;
`}
`
*/
const P = styled.p`
  font-weight: bolder;
  font-weight: 1.1em;
  font-size: 1.2em;
  margin: 5px 0 18px;
  opacity: 0.8;
  ${media.lessThan('1044px')`
  margin: 25px 0 2px;
`}
`
const A = styled.a`
  font-weight: bold;
  padding: 5px 5px 5px 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  ${media.lessThan('1044px')`
  padding: 2px 5px 2px 0;
`}
`

const SLowSection = styled.div`
  height: 75px;
  font-size: 1em;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1787e0;
  ${media.lessThan('1044px')`
    font-size: .8em;
    height: 125px;
    margin-top: -3em;
  `};
`
/*
const SIcon = styled.a`
  height: 50px;
  width: 50px;
  object-fit: contain;
  & img {
    transition: all 0.2s ease;
  }
  &:hover img {
    cursor: pointer;
    transform: translateY(-8px);
    transform: scale(1.1);
  }
`

const SP = styled.p`
  max-width: 487px;
  line-height: 1.29;
  margin: 20px auto;

  ${media.lessThan('1440px')`
    width: 100%;
    font-size: 1em;
  `}
`
*/
const PaperRights = styled.span`
  margin-right: 14px;
  ${media.lessThan('1044px')`
    margin-right: 0;
    margin-top:20px;
  `}
`
/*
const PaperMentions = styled(NavLink)`
  font-weight: bold;
  margin-left: 14px;
  margin-right: 14px;
  ${media.lessThan('1044px')`
    margin-left: 0;
  `}
`

const PaperCGU = styled(NavLink)`
  font-weight: bold;
  margin-left: 14px;
  ${media.lessThan('1044px')`
    margin-left: 0;
  `}
`
*/
const BadgesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 -8px;
  ${media.lessThan('1025px')`
    width: 170px;
    margin: 5px auto 20px;
  `}
`

const Ios = styled(IosBadge)`
  margin: 0px 5px !important;
`

const Android = styled(AndroidBadge)`
  margin: 0px 5px !important;
`
export default withRouter(Footer)
