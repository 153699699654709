import axios from 'axios'

export const getHitById = async id => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/hits/${id}`
    )
    return res.data[0]
  } catch (err) {
    throw err
  }
}

export const getProgramByProgramID = async id => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/programs/${id}`
    )
    return res.data[0]
  } catch (err) {
    throw err
  }
}
