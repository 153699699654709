import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

import theme from 'utils/theme'

import Icn from 'assets/home/icn-trouvez.svg'
import Notification from 'assets/home/notification.png'

function Section5() {
  return (
    <SectionContainer>
      <End>
        <Block>
          <SectionIcn src={Icn} />
          <Title>
            <Blue>Trouvez</Blue> les meilleurs professionnels pour votre projet
          </Title>

          <Text>
            Vous souhaitez déménager l’esprit tranquille ? Réduire votre facture
            d’énergie ? Economiser sur votre assurance habitation ?
            <br />
            <br />
            Profitez de services personnalisés pour vous accompagner à chaque
            étape de votre projet :
            <Partenaires>
              <Partenaire src="images/logo-hypo.png" />
              <Partenaire src="images/logo-nextories.png" />
              <Partenaire src="images/logo-mci.png" />
              <Partenaire src="images/logo-lelynx.png" />
            </Partenaires>
            <MoreText>... et bien d’autres</MoreText>
          </Text>
        </Block>
        <Img loading="lazy" src={Notification} />
      </End>
    </SectionContainer>
  )
}

const End = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width:1200px;
  margin: auto;

  ${media.lessThan('1170px')`
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    margin-bottom: 20px;
    margin: auto 5%;
  `}


  ${media.greaterThan('1170px')`
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
  `}

  ${media.lessThan('1440px')`
    padding: 10px 0;
  `}

`

const SectionContainer = styled.div`
  background-color: ${theme.colors.light_primary};
  width: 100%;
  padding: 60px 0;

  ${media.lessThan('1170px')`
    padding: 20px 0;
  `}
  ${media.greaterThan('1170px')`
    padding: 60px 0 80px;
  `}
`

const Block = styled.div`
  position: relative;
  max-width: 360px;
  width: auto;
  padding-top: 40px;
  padding-left: 10%;
  flex: 2;

  ${media.lessThan('768px')`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
  ${media.between('768px', '1170px')`
    max-width: 550px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto 5%;
    padding: 0;
  `}
`

const SectionIcn = styled.img`
  flex: 1;
  height: auto;
  width: 75px;
  object-fit: contain;
  margin-bottom: 15px;
  ${media.lessThan('1025px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 10px 10px 20px 10px;
    text-align:center;
  `}
`

const Img = styled.img`
  flex: 1;
  content: url('/images/section5.png');
  height: auto;
  width: 100%;
  max-height: 500px;
  object-fit: contain;

  ${media.lessThan('768px')`
    max-height: 380px;
    margin: 20px auto 10px;
  `}

  ${media.between('768px', '1170px')`
  position: relative;
  top: 230px;
  margin-bottom: 250px;
  `}
`

const Title = styled.div`
  font-size: 1.8em;
  font-weight: 800;
  color: ${theme.colors.secondary};
  margin-bottom: 30px;
  line-height: 1.1em;

  ${media.lessThan('768px')`
    font-size: 1.4em;
    line-height: 22px;
    margin: 10px auto 30px auto;
    text-align:center;
  `}
  ${media.between('768px', '1170px')`
    margin: 10px auto 20px auto;
    text-align:center;
  `}
  ${media.greaterThan('1170px')`
    margin: 10px auto 20px auto;
  `}
`

const Text = styled.div`
  font-size: 1em;
  font-weight: 600;
  text-align: justify;
  color: #114b62;

  ${media.lessThan('768px')`
  text-align: justify;
  `}

  ${media.between('768px', '1170px')`
    margin-top: 10px;
    height: 72px;
    font-size: 1em;
    max-width: 370px;
    text-align: justify;
  `}

  ${media.greaterThan('768px')`
    margin-top: 10px;
    height: 72px;
    font-size: 1em;
  `}

  ${media.greaterThan('1170px')`
    text-align: left;
    padding: 0;
    margin: 10px auto 20px auto;
  `}
`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const Partenaires = styled.div`
  margin: 40px auto auto;

  ${media.between('768px', '1170px')`
    justify-content: center;
    display: flex;
  `}

  ${media.lessThan('1170px')`
  text-align: center;
  `}
`

const Partenaire = styled.img`
  border-radius: 100px;
  width: 60px;
  height: 60px;
  margin-right: 8px;
  object-fit: contain;
  box-shadow: 0 2px 3px rgb(0 0 0 / 10%);
`

const MoreText = styled.p`
  color: grey;
  text-align: center;
`

export default Section5
