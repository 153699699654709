import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'
import { contactUs } from 'services/contact'
import * as validators from 'utils/validators'
import { useToasts } from 'react-toast-notifications'

import { useInput } from 'hooks/input.hooks'
import Input from 'components/input'
import Button from 'components/button'
import theme from 'utils/theme'

function AgencyForm({ textAreaRows }) {
  const [disabled, setDisabled] = useState(false)

  const { addToast } = useToasts()

  const {
    value: fullname,
    onChange: onChangeName,
    reset: resetName
  } = useInput('')

  const { value: city, onChange: onChangeCity, reset: resetCity } = useInput('')

  const {
    value: software,
    onChange: onChangeSoftware,
    reset: resetSoftware
  } = useInput('')

  const {
    value: softwareID,
    onChange: onChangeSoftwareID,
    reset: resetSoftwareID
  } = useInput('')

  const {
    value: company,
    onChange: onChangeCompany,
    reset: resetCompany
  } = useInput('')

  const { value: email, onChange: onChangeEmail, reset: resetEmail } = useInput(
    ''
  )

  /*const {
    value: phoneNumber,
    onChange: onChangePhoneNumber,
    reset: resetPhoneNumber
  } = usePhoneInput('')
*/
  const {
    value: message,
    onChange: onChangeMessage,
    reset: resetMessage
  } = useInput('')

  useEffect(() => {
    if (
      !validators.isEmailValid(email) ||
      !validators.isContactMessageValid(message) ||
      !company ||
      !fullname ||
      !city
    ) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [email, message, company, fullname, city, softwareID])

  const send = async () => {
    const data = {
      company,
      city,
      fullname,
      email,
      software,
      softwareID,
      message
    }

    try {
      await contactUs(data)
      addToast('Votre message a été envoyé', {
        appearance: 'success',
        autoDismiss: true
      })
      resetCompany()
      resetCity()
      resetName()
      resetEmail()
      resetSoftware()
      resetSoftwareID()
      resetMessage()
    } catch (err) {
      console.error(err)
      addToast('Oups, une erreur est survenue ! Veuillez réessayer', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }

  return (
    <React.Fragment>
      <FormCenter>
        <Input
          label="Nom de votre agence *"
          placeholder="Ex: AgenceImmo"
          name="company"
          value={company}
          required={true}
          onChange={onChangeCompany}
        />
      </FormCenter>

      <FormCenter>
        <Input
          label="Ville / CP de votre agence *"
          placeholder="Ex: Puteaux (92800)"
          name="city"
          value={city}
          required={true}
          onChange={onChangeCity}
        />
      </FormCenter>

      <FormCenter>
        <Input
          label="Nom complet *"
          placeholder="Ex: Paul Dupond"
          name="fullname"
          value={fullname}
          required={true}
          onChange={onChangeName}
        />
      </FormCenter>

      <FormCenter>
        <Input
          label="Email *"
          placeholder="Ex: paul.dupond@gmail.com"
          name="email"
          value={email}
          error={email && !validators.isEmailValid(email)}
          required={true}
          onChange={onChangeEmail}
        />
      </FormCenter>

      <FormCenter>
        <Select
          label="Logiciel de transaction"
          placeholder="Ex: Apimo"
          name="software"
          value={software}
          error={software}
          required={true}
          onChange={onChangeSoftware}
          className=""
        >
          <option value="null">Logiciel de transaction</option>
          <option value="manual">Je n'ai pas de logiciel</option>
          <option value="ac3">Ac3 - Immo-Facile - Krier - Alize</option>
          <option value="acheterlouer">Acheter-Louer</option>
          <option value="activimmo">Activimmo</option>
          <option value="adaptimmo">Adaptimmo</option>
          <option value="agenceplus">AgencePlus</option>
          <option value="apimo">Apimo</option>
          <option value="apimo_json">Apimo-Json</option>
          <option value="arkonet">Arkonet</option>
          <option value="chronotech">Chronotech</option>
          <option value="consortium">Consortium</option>
          <option value="cosmosoft">Cosmosoft</option>
          <option value="crypto">Crypto - PagesIMMO - Gercop</option>
          <option value="elephant">ElephantBird / Fnaim Connect</option>
          <option value="emoweb">Emoweb / Spi Transaction</option>
          <option value="era">Era</option>
          <option value="hektor">Hektor</option>
          <option value="horizon">Horizon</option>
          <option value="ics">Ics Transac.Net</option>
          <option value="idmedias">DriveFox, IdMedias</option>
          <option value="imminence">Imminence - Emulis</option>
          <option value="immonot">Immonot</option>
          <option value="immoone">Immo-One, E-Transac, Technolog</option>
          <option value="immoonee2">Immo-One E2</option>
          <option value="immoprice">Immoprice</option>
          <option value="immostore">Immostore</option>
          <option value="immovision">Immovision</option>
          <option value="lesty">Lesty</option>
          <option value="negoweb">Negoweb</option>
          <option value="netty">Netty</option>
          <option value="nomadimmo">Nomadimmo</option>
          <option value="orpi">Orpiconnect</option>
          <option value="partagimmo">Partagimmo</option>
          <option value="pericles">Pericles</option>
          <option value="periclesair">Pericles Air</option>
          <option value="poliris">Poliris</option>
          <option value="rodacom">
            Rodacom, ImmoQuartz, Connect’Immo, Sphere
          </option>
          <option value="soqrate">Soqrate</option>
          <option value="transactimmo">Transactimo</option>
          <option value="transellis">Transellis</option>
          <option value="twimmo">Twimmo</option>
          <option value="ubiflow">Ubiflow</option>
          <option value="visualqie">Visualqie - One Expert</option>
          <option value="win">Win Immobilier</option>
          <option value="wizzimmo">Wizzimmo</option>
          <option value="other">Mon logiciel n'est pas dans la liste</option>
        </Select>
      </FormCenter>

      <FormCenter>
        <Input
          label="Identifiant logiciel"
          placeholder="Ex: 12345"
          name={softwareID}
          value={softwareID}
          onChange={onChangeSoftwareID}
        />
      </FormCenter>

      <FormCenter
        style={{
          height: '120',
          width: '100%'
        }}
      >
        <Input
          type="textArea"
          rows={textAreaRows}
          label="Message *"
          placeholder="Votre message..."
          name="message"
          value={message}
          error={message && !validators.isContactMessageValid(message)}
          required={true}
          onChange={onChangeMessage}
          style={{
            padding: '7px 9px',
            width: '100%',
            maxWidth: '500px !important'
          }}
        />
      </FormCenter>

      <MessageContent>
        {disabled && (
          <MessageErr>
            Les champs marqués d'un asterisque (*) sont obligatoires
          </MessageErr>
        )}
      </MessageContent>

      <FormCenter>
        <Button disabled={disabled} onClick={send}>
          Envoyer
        </Button>
      </FormCenter>
    </React.Fragment>
  )
}

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const MessageErr = styled.div`
  color: ${theme.colors.scarlet};
  text-align: center;
  margin-bottom: 10px;
}
`
const MessageContent = styled(Center)`
  margin-bottom: 14px;
  margin: auto;
  padding-bottom: 5px;
  width: 100%;
  &:last-child {
    padding-bottom: 0;
  }
  ${media.lessThan('1025px')`
    padding-bottom: 5px;
    align-items: center;
  `}
`

const Select = styled.select`
  width: 100%;
  height: 100%;
  min-height: 44px;
  box-sizing: border-box;
  border-radius: 10px;
  border: solid 1px #9e9e9e;
  font-size: 1em;
  color: #114b62;
  padding: 5px 10px;
  font-family: NunitoSans;
  -webkit-appearance: none;
  top: 3px;
  position: relative;
  margin: 5px;
  ${media.lessThan('1025px')`
    margin: auto 14px;
  `}

  ${media.greaterThan('1025px')`

  `}
`

const FormCenter = styled(Center)`
  padding: 10px 5px 20px 5px;
  &:last-child {
    padding-bottom: 0;
  }
  ${media.lessThan('1025px')`
    width: 100%;
    padding-bottom: 10px;
    align-items: center;
  `}

  ${media.greaterThan('1025px')`
    width: 48%;
  `}


  & select {
    width: 100%;
  }
`

//const Test = styled.div``

export default AgencyForm
