import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

import BadgeFree from 'assets/broadcast/illu-free.png'
import BadgeFree2 from 'assets/broadcast/illu-free.png'
import BadgeFree3 from 'assets/broadcast/illu-free.png'
import BadgeMulti from 'assets/broadcast/illu-multicanal.png'
import BadgeMulti2 from 'assets/broadcast/illu-multicanal.png'
import BadgeMulti3 from 'assets/broadcast/illu-multicanal.png'
import BadgeFlash from 'assets/broadcast/illu-realtime.png'
import BadgeFlash2 from 'assets/broadcast/illu-realtime.png'
import BadgeFlash3 from 'assets/broadcast/illu-realtime.png'
import BadgeExh from 'assets/broadcast/illu-formats.png'
import BadgeExh2 from 'assets/broadcast/illu-formats.png'
import BadgeExh3 from 'assets/broadcast/illu-formats.png'
import BadgeBalance from 'assets/broadcast/illu-privacy.png'
import BadgeBalance2 from 'assets/broadcast/illu-privacy.png'
import BadgeBalance3 from 'assets/broadcast/illu-privacy.png'

import BadgeBlock from './BadgeBlock'
import SectionTitle from 'components/sectionTitle'

function Section1() {
  return (
    <Wrapper>
      <Title>
        Pourquoi <Blue>diffuser</Blue> sur <Blue>Arlet</Blue> ?
      </Title>

      <Section>
        <BadgeBlock
          img={BadgeFree}
          srcSet={`${BadgeFree2} 2x, ${BadgeFree3} 3x`}
          text="Une diffusion totalement gratuite."
        />

        <BadgeBlock
          img={BadgeMulti}
          srcSet={`${BadgeMulti2} 2x, ${BadgeMulti3} 3x`}
          text="Disponible sur iOS, Android et le Web."
        />

        <BadgeBlock
          img={BadgeFlash}
          srcSet={`${BadgeFlash2} 2x, ${BadgeFlash3} 3x`}
          text="Envoi des contacts dans vos outils en temps-réel."
        />

        <BadgeBlock
          img={BadgeExh}
          srcSet={`${BadgeExh2} 2x, ${BadgeExh3} 3x`}
          text="Compatible avec tous les formats de flux d’annonces."
        />

        <BadgeBlock
          img={BadgeBalance}
          srcSet={`${BadgeBalance2} 2x, ${BadgeBalance3} 3x`}
          text="Respect de la protection des données personnelles (conforme RGPD & loi Alur)."
        />
      </Section>
    </Wrapper>
  )
}

const Blue = styled.span`
  color: ${theme.colors.primary};
`

const Title = styled(SectionTitle)`
  margin: 50px auto 0px auto !important;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column !important;
  position: relative;
  background: #f3f9fd;
  margin: 0px auto 50px auto;
  ${media.between('767px', '1025px')`
    flex-direction: row;
  `}
`

const Section = styled.div`
  display: flex;
  justify-content: center;
  z-index: 0;
  position: relative;
  margin: 0 auto 50px auto;
  max-width: 800px;
  flex-wrap: wrap;
  justify-content: space-evenly;
  ${media.lessThan('1025px')`
    flex-direction: column;
    align-items: center;
    margin: 5px auto;
    padding-bottom: 30px;
  `}
`

export default Section1
