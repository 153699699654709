import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import media from 'styled-media-query'
import theme from 'utils/theme'

import SectionTitle from 'components/sectionTitle'
//import AgencyForm from 'components/agencyForm'
import Card from 'components/cards'
/*
const scrollToRef = formRef => {
  formRef.current.scrollIntoView()
}
*/
function Section2(props) {
  //const [sticky, setSticky] = useState(true)
  //const [footerPos, setFooterPos] = useState(true)
  //const isMobile = window.innerWidth <= 1025 ? true : false
  //const isBgColored = true

  const [activeTab, setActiveTab] = useState(0)

  const toggleTab = tabName => {
    setActiveTab(tabName)
  }

  return (
    <React.Fragment>
      <Section>
        <SectionTitle
          style={{
            height: 'auto',
            padding: '0 5% 30px 5%',
            margin: '60px auto 40px'
          }}
        >
          Notre <Blue>sélection de programmes</Blue> neufs
        </SectionTitle>
      </Section>

      <Row>
        <SubTitle>
          Retrouvez dans l’application tous nos programmes neufs sur toute la
          France
        </SubTitle>
        <FlexContainer>
          <Center
            active={activeTab === 0 ? 'true' : 'false'}
            onClick={() => toggleTab(0)}
          >
            Paris / IDF
          </Center>
          <Center
            active={activeTab === 1 ? 'true' : 'false'}
            onClick={() => toggleTab(1)}
          >
            Lyon
          </Center>
          <Center
            active={activeTab === 2 ? 'true' : 'false'}
            onClick={() => toggleTab(2)}
          >
            Nantes
          </Center>
          <Center
            active={activeTab === 3 ? 'true' : 'false'}
            onClick={() => toggleTab(3)}
          >
            Marseille
          </Center>
        </FlexContainer>

        <CardsContainer>
          <Card id="785INLP75MVUKOJZLVD" />
          <Card id="785INLP75MVUKOJZLVD" />
          <Card id="785INLP75MVUKOJZLVD" />
        </CardsContainer>
      </Row>
    </React.Fragment>
  )
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 600px;
  ${media.lessThan('1025px')`
    align-items: center;
    justify-content: flex-start;
  `}
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 600px;
  ${media.lessThan('1025px')`
    align-items: center;
    justify-content: flex-start;
  `}
`

const Center = styled.a`
  ${props => `
    background: ${props.active === 'true' ? '#1787E0' : '#E8F3FC'};
    color: ${props.active === 'true' ? '#ffffff' : '#1787E0'};
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5px;
    padding: 15px 25px;
    border-radius: 8px;
    ${media.lessThan('1025px')`
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    `}
  `}
`

const Section = styled(Center)`
  display: flex;
  width: 100%;
  position: relative;
  background: #ffffff;
  ${media.lessThan('1025px')`
  `}
`

const Row = styled(Center)`
  margin: -50px 0px 40px 0px;
  position: relative;
  align-items: center;
  background: #ffffff;
  ${media.lessThan('1025px')`
  `}
`
/*
const Row2 = styled(Row)`
  border-radius: 50px;
  height: 80px;
  width: 80px;
  background: #ffffff;
  font-weight: bold;
  margin: auto;
  margin-bottom: 70px;
  top: -20px;
  color: ${theme.colors.secondary};
  font-size: 1.5em;
  ${media.lessThan('1025px')`
  justify-content: center;
  `}
`
*/
const Blue = styled.span`
  color: ${theme.colors.primary};
`

const SubTitle = styled.p`
  padding: 0 20px;
  font-size: 1.2em !important;
  font-weight: 500 !important;
  position: relative;
  text-align: center;
  color: ${theme.colors.secondary};
`
/*
const Img = styled.img`
  margin-top: 70px;
  object-fit: contain;
  max-width: 200px;
  min-height: 80px;
  margin: 10px;

  ${media.lessThan('1025px')`
    width: auto;
    margin-top: 20px;
    max-width: 130px;
    min-height: 80px;
    margin: 0px;
  `}

  ${media.lessThan('350px')`
    max-width: 100px;
    margin: 5px 10px;
  `}

  ${media.between('350px', '1025px')`
    max-width: 170px;
  `}
`
*/
/*
const Cta = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  flex-shrink: 0;
  z-index: 998;
  // bottom: 5vw;
  bottom: -1vw;
  left: 0;
  right: 0px;
  width: calc(100% - 40px);
  height: 80px;
  background-color: #e84c5c;
  color: #ffffff;
  padding: 0 20px;
  font-weight: 800;
  margin: auto;
  text-align: center;
  //box-shadow: 0 2px 47px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;

  ${media.greaterThan('1025px')`
  display: none;
`}
`

const WhiteOverlay = styled.div`
  position: fixed;
  height: 55px;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  width: 100%;
  top: 14px;
  border-radius: 20px;
  z-index: 99;

  &.show {
    top: 3%;
    transition: all 0s ease 0s;
    visibility: hidden !important;
  }
  &.hide {
    visibility: visible !important;
    transition: all 0s ease 0.5s;
  }

  ${media.lessThan('812px')`
      top: 19px;
    `}

    ${media.lessThan('768')`
      top: 14px;
    `}

  ${media.greaterThan('1025px')`
    width: 439px;
    &.show, .hide {
      visibility: visible !important;
      top: 0;
    }

  `}
`
*/
/*
const FormTitle = styled.p`
  color: ${theme.colors.secondary};
  width: 80%;
  text-align: center;
  font-size: 1.1em;
  ${media.greaterThan('1025px')`
    margin: 10px auto 19px auto;
  `}
`

const FormContainer = styled.div`
  margin-top: 15px;
  ${media.lessThan('1025px')`
    margin-top: 0;
  `}
`
*/
export default Section2
