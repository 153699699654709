import React from 'react'
import styled, { css } from 'styled-components'
import media from 'styled-media-query'
import theme from 'utils/theme'

function Button({ children, onClick, disabled }) {
  const _onClick = e => {
    !disabled && onClick && onClick(e)
  }
  return (
    <SButton disabled={disabled} onClick={_onClick}>
      {children}
    </SButton>
  )
}

const SButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 44px;
  border-radius: 60px;
  font-size: 1em;
  max-width: 300px;
  color: #ffffff;
  background-color: ${theme.colors.primary};
  ${props =>
    props.disabled
      ? css`
          background: ${theme.colors.buttonDisabled};
          color: ${theme.colors.buttonDisabledText};
          cursor: not-allowed;
          &:hover {
            cursor: not-allowed;
          }
        `
      : css`
          cursor: pointer;
        `}
  font-size: 17px;
  font-weight: bold;

  ${media.lessThan('1025px')`
    width: 100vw;
    height: 44px;
    border-radius: 60px;
    font-size: 1em;
  `}
`
export default Button
