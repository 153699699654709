import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import media from 'styled-media-query'
import { capitalizeEachWord, capitalizeFirstLetterOnly } from 'utils/string'
import { getActiveSpec, getRedirectFromSpec } from 'utils/hit'
import { HitType } from 'constants.js'

// import { useFetchData } from 'hooks/fetch.hooks'
import { getHitById, getProgramByProgramID } from 'services/hits'
import Gallery from './Gallery'
//import LikeButton from './LikeButton'
import ProgramTitle from './ProgramTitle'
import Title from './Title'
import Characteristics from './Characteristics'
import Location from './Location'
import Description from './Description'
import Ref from './Ref'
import AboutPrice from './AboutPrice'
import Energy from './Energy'
import InfoContact from './InfoContact'
import Banner from './Banner'
import ProgramLot from './ProgramLot'

const NotFound = () => {
  return (
    <Container>
      <SDetailPaper>
        <CenterError>
          <SError>
            <ESImage
              src="/images/empty_state_ko.png"
              alt="Oops ! Une erreur est survenue"
            />
            <div>Oops ! L'annonce n'a pas pu être récupérée</div>
          </SError>
        </CenterError>
      </SDetailPaper>
      <Banner absolute="absolute" />
    </Container>
  )
}

// 32WC4YJ081LI9N86D83
function DetailPaper({
  match: {
    params: { id }
  }
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [hit, setHit] = useState(null)
  const [program, setProgram] = useState(null)
  if (!window.location.href.includes('detail-annonce')) {
    window.location.href = `/detail-annonce/${id}`
  }

  useEffect(() => {
    //scroll to top of page
    window.scrollTo(0, 0)

    async function fetch(id) {
      let hit, program
      try {
        hit = await getHitById(id)
        setHit(hit)
      } catch (err) {
        console.error(err)
        setIsLoading(false)
        return
        // return Promise.reject(err)
      }

      if (hit && hit.T === 'typology' && hit.program_reference) {
        try {
          program = await getProgramByProgramID(hit.program_reference)
          setProgram(program)
        } catch (err) {
          console.error(err)
          setIsLoading(false)
          return
        }
      }

      setIsLoading(false)
    }
    fetch(id)
  }, [id])

  if (isLoading) return null

  const spec = hit ? getActiveSpec(hit) : undefined
  if (!hit || !spec) {
    return <NotFound />
  }
  /*
  const price = program
    ? Math.min(
        ...program.details.program.ads.map(lot => lot.price)
      )
    : hit?.tariffs?.PriceIncludingTax?.price
*/
  const redirect = getRedirectFromSpec(spec)

  return (
    <Container>
      <SDetailPaper>
        <MobileContainer>
          <Center>
            <Gallery images={hit.medias} />
          </Center>
        </MobileContainer>

        <Center>
          <SInfo>
            <MobileContainer>{/*<LikeButton />*/}</MobileContainer>
            {!!program && <ProgramTitle title={program.title} />}
            <Title
              title={`${capitalizeFirstLetterOnly(
                HitType[hit.type]
              )} à ${capitalizeEachWord(hit.location.city || '')}`}
              priceLabel={!!program ? `À partir de` : ''}
              price={
                hit.tariffs &&
                hit.tariffs.PriceIncludingTax &&
                hit.tariffs.PriceIncludingTax.price
              }
              category={hit.category}
            />

            <Characteristics hit={hit} program={program} />

            <DesktopContainer>
              <Gallery images={hit.medias} />
            </DesktopContainer>

            {!!hit.geolocation && (
              <Location lat={hit.geolocation.lat} lng={hit.geolocation.lng} />
            )}

            <Description
              shortText={program?.description?.short || hit.description.short}
              longText={program?.description?.long || hit.description.long}
            />

            <Ref data={hit} createdAtOnly={!!redirect.url} />

            {program ? (
              <ProgramLot program={program} />
            ) : (
              <AboutPrice data={hit} />
            )}

            {!program && !redirect.url && (
              <Energy isOld={hit.is_old} data={hit.energy} />
            )}

            {!redirect.url && <InfoContact hit={hit} program={program} />}
          </SInfo>
        </Center>
      </SDetailPaper>
      <Banner />
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: auto;
  // min-height: calc(100vh - 374px);
  ${media.lessThan('1026px')`
    margin-bottom: 20px;
  `}
`
const CenterError = styled(Center)`
  ${media.lessThan('1026px')`
  min-height: calc(100vh - 260px);
  margin-bottom: 0px;
`}
`

const ESImage = styled.img`
  width: 50vw;
  max-width: 270px;
  height: auto;
  margin: 0;
  margin-bottom: 30px;
  display: flex;
  margin-bottom: 10px;
`

const DesktopContainer = styled.div`
  ${media.lessThan('1026px')`
    display: none;
  `}
`

const MobileContainer = styled.div`
  ${media.greaterThan('1025px')`
    display: none;
  `}
`

const SDetailPaper = styled.div`
  flex: 1 0 auto;
  margin: 30px 0px;
  min-height: calc(100vh - 261px);

  ${media.lessThan('1026px')`
    margin: 0px;
  `}
`

const SInfo = styled.div`
  position: relative;
  box-shadow: 0 2px 87px 0 rgba(85, 163, 181, 0.1);
  background-color: #ffffff;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  border-radius: 25px;
  font-size: 20px;

  ${media.lessThan('1025px')`
    padding: 15px;
    width: 100%;
    margin-top: -43px;
    border-radius: 19px 19px 0px 0px;
    font-size: 16px;
  `}
`

const SError = styled(SInfo)`
  font-size: 30px;
  font-weight: 900;
  color: #114b62;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.lessThan('1025px')`
    font-size: 1em;
    padding: 15px;
    width: 100%;
    margin-top: 0px;
  `}
`

export default withRouter(DetailPaper)
