import React from 'react'
import styled from 'styled-components/macro'
//import { firstOptions, secondOptions } from 'interface/properties'
//import get from 'lodash/get'
import media from 'styled-media-query'
import theme from 'utils/theme'
import Icon from 'components/icon'
import { getHitDetail, getProgramDetail } from 'utils/hit'

//import { uid } from 'utils'

function Characteristic({ label, value, icon, type, isCard }) {
  return isCard === 'true' ? (
    <SCharacteristic className="cardLabel">{label}</SCharacteristic>
  ) : (
    <SCharacteristic>
      <Icon name={icon} color={theme.colors.primary} /> {label}
    </SCharacteristic>
  )
}

function Characteristics({ hit, program, isCard }) {
  //const allOptions = [...firstOptions, ...secondOptions]
  //const hitOptions = [...new Set(hit.details.options)] // Remove duplicate
  //const icons = []
  const details = !!program ? getProgramDetail(program) : getHitDetail(hit)

  return isCard === 'true' ? (
    <SCharacteristics>
      <Characteristic
        isCard="true"
        key={details[1].label}
        label={details[1].label}
        icon={details[1].icon}
      />
      <Characteristic
        isCard="true"
        key={details[3].label}
        label={details[3].label}
        icon={details[3].icon}
      />
      <Characteristic
        isCard="true"
        key={details[4].label}
        label={details[4].label}
        icon={details[4].icon}
      />
    </SCharacteristics>
  ) : (
    <SCharacteristics>
      {details.map(item => {
        return (
          <Characteristic
            key={`${item.icon}`}
            label={item.label}
            icon={item.icon}
          />
        )
      })}
    </SCharacteristics>
  )
}

const SCharacteristics = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
`

const SCharacteristic = styled.div`
  font-size: 0.85em;
  margin-bottom: 10px;
  color: ${theme.colors.secondary};
  padding-right: 10px;
  width: 25%;
  box-sizing: border-box;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  ${media.lessThan('1025px')`
    font-size: 13px;
  `}

  ${media.lessThan('812px')`
    width: 50%;
  `}

  &.cardLabel {
    width: 33% !important;
    text-align: center;
  }
`

export default Characteristics
