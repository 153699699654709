import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'
import { useInput } from 'hooks/input.hooks'
import { subscribe } from 'services/newsletter'
import { useToasts } from 'react-toast-notifications'
import * as validators from 'utils/validators'
import theme from 'utils/theme'

//import IG3 from 'assets/footer/social-ig@3x.png'
//import IG2 from 'assets/footer/social-ig@2x.png'
//import IG from 'assets/footer/social-ig.png'
//import Arrow1 from 'components/icon/arrow'

function ComplementFooter() {
  const [disabled, setDisabled] = useState(false)
  const { value: email, onChange: onChangeEmail, reset: resetEmail } = useInput(
    ''
  )
  const { addToast } = useToasts()
  const onSubmit = async () => {
    if (disabled) {
      addToast("Format d'email invalide !", {
        appearance: 'error',
        autoDismiss: true
      })
      return
    }
    try {
      await subscribe({ email })
      resetEmail('')
      addToast('Merci ! Vous êtes inscrit à la newsletter Arlet.', {
        appearance: 'success',
        autoDismiss: true
      })
    } catch (err) {
      console.error(err)
      addToast('Oups, Une erreur est survenue !', {
        appearance: 'error',
        autoDismiss: true
      })
    }
  }
  useEffect(() => {
    if (!validators.isEmailValid(email)) {
      setDisabled(true)
    } else {
      setDisabled(false)
    }
  }, [email])

  return (
    <React.Fragment>
      <Section>
        <SectionContent>
          <Newsletter>
            <Text>Inscrivez-vous à la newsletter</Text>
            <SP margin="20px auto 40px;">
              En nous indiquant votre adresse e-mail, vous recevrez nos guides
              et notre actualité.
            </SP>
            <Wrapper>
              <SInput
                type="text"
                value={email}
                onChange={onChangeEmail}
                placeholder="Entrez votre adresse email..."
                noBorder
              />
              <Arrow disabled={disabled} onClick={onSubmit}>
                Envoyer
              </Arrow>
            </Wrapper>
            <SP margin="20px auto;">
              Vous pouvez vous désinscrire à tout moment.
            </SP>
          </Newsletter>
        </SectionContent>
      </Section>
    </React.Fragment>
  )
}

const Section = styled.div`
  padding: 30px 10px;
  font-size: 17px;
  text-align: center;
  background: ${theme.colors.primary};
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
`

const SectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.greaterThan('1920px')`
  width: 80%;
  margin: auto;
  `}

  ${media.lessThan('1920px')`
  width: 80%;
  margin: auto;
  `}

  ${media.lessThan('1440px')`
  width: 90%;
  margin: auto 5%;
  padding: 0px;
  `}

  ${media.between('1025px', '1440px')`
    width: 100%;
  `}

  ${media.lessThan('1025px')`
  flex-direction: column;
  margin: auto 5%;
  `}

  ${media.greaterThan('1025px')`
    flex-direction: row;
    margin: auto;

  `}
`

const Text = styled.div`
  font-size: 1.8em;
  font-weight: 800;
  text-align: center;
  z-index: 0;
  height: auto;
  width: 100%;
  margin: 30px auto -6px auto;
  color: #ffffff;

  ${media.lessThan('1025px')`
  font-size: 1.7em;
  line-height: 1.2;
  padding: auto 20px;
`}

  ${media.greaterThan('1025px')`
  min-width: 430px;
`}
`

const SP = styled.p`
  max-width: 487px;
  line-height: 1.29;
  margin: ${props => props.margin};
  color: rgba(255, 255, 255, 0.8);
  ${media.lessThan('1440px')`
    width: 100%;
    font-size: 1em;
  `}
`

const Newsletter = styled.div`
  width: 100%;
  flex: 1;
  align-self: center;
  ${media.lessThan('1044px')`
    display: flex;
    flex-direction: column;
    align-items: center;
  `}
`

const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 540px;
  margin: auto;
`

const Arrow = styled.div`
  width: 110px;
  padding: 8px 5px;
  height: 19px;
  font-weight: bold;
  line-height: 19px;
  position: absolute;
  top: 5px;
  right: 6px;
  border-radius: 30px;
  color: ${props => (props.disabled ? '#808080' : '#FFFFFF')};
  background: ${props => (props.disabled ? '#cccaca' : '#E72E46')};

  &:hover {
    cursor: pointer;
  }

  ${media.lessThan('768px')`
  top: 58px;
  right: 50%;
  transform: translateX(50%);
  `}
`

const SInput = styled.input`
  height: 29px;
  width: 100%;
  border-radius: 50px;
  background-color: #ffffff;
  font-size: 17px;
  color: #114b62;
  padding: 8px 32px 8px 15px;
  border: none;

  ::placeholder {
    color: #b4b4b4;
  }
  :-ms-input-placeholder {
    color: #b4b4b4;
  }
  ::-ms-input-placeholder {
    color: #b4b4b4;
  }
  &:focus,
  &:hover {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  }
  ${media.lessThan('768px')`
    margin-bottom: 45px;
  `}
`

export default ComplementFooter
