import React, { useState, useEffect } from 'react'

import styled from 'styled-components/macro'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ScrollToTop from 'react-router-scroll-top'
import { ToastProvider, DefaultToast } from 'react-toast-notifications'
import axios from 'axios'

import Header from 'components/header'
import Footer from 'components/footer'
import Home from 'pages/home'
import HitDetail from 'pages/hitDetail'
import ListDetail from 'pages/listDetail'
import RedirectAppStore from 'pages/redirectAppStore'
import Broadcast from 'pages/broadcast'
import Contact from 'pages/contact'
import ForgottenPassword from 'pages/forgottenPassword'
import Neuf from 'pages/neuf'
import Press from 'pages/press'
import Terms from 'pages/terms'
import CGU from 'pages/cgu'
import ThankYou from 'pages/thankyou'
import NotFound from 'pages/not-found'
import ShouldUpdate from 'pages/shouldUpdate'
import { Device, Size } from 'constants'
//import CookieBot from 'react-cookiebot/lib/CookieBot'
import ProspectArea from './pages/prospect-area'

axios.defaults.headers.common['X-Api-Version'] = '2'

function App() {
  const [hasCookieBot, setHasCookieBot] = useState(undefined)
  //
  //
  //
  //cookiebot paper
  //const domainGroupId = '0e5a7c33-4cd5-436b-9d42-3c402c09c84c'

  //cookiebot arlet
  /* commenté car ca conflict avec la page cgu */
  // const domainGroupId = 'ea4bd7ed-8906-4815-97b9-c42a63901e9b'

  return (
    <Container className="app">
      <ToastProvider
        components={{ Toast: MyCustomToast }}
        style={{ width: '100vw !important' }}
      >
        <Router>
          <ScrollToTop>
            <Header />
            <Content>
              {/*<CookieBot domainGroupId={domainGroupId} />*/}
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/detail-paper/:id" component={HitDetail} />
                <Route exact path="/detail-annonce/:id" component={HitDetail} />
                <Route exact path="/list/:id" component={ListDetail} />
                <Route exact path="/rd/:id" component={RedirectAppStore} />
                <Route exact path="/broadcast" component={Broadcast} />
                <Route exact path="/contact" component={Contact} />
                <Route exact path="/mdp-oublie" component={ForgottenPassword} />
                <Route exact path="/neuf" component={Neuf} />
                <Route exact path="/presse" component={Press} />
                <Route exact path="/mentions-legales" component={Terms} />
                <Route exact path="/cgu-confidentialite" component={CGU} />
                <Route exact path="/merci" component={ThankYou} />
                <Route exact path="/should-update" component={ShouldUpdate} />
                <Route path="/espace-client/:page*" component={ProspectArea} />
                <Route component={NotFound} />
              </Switch>
            </Content>
            <Footer />
          </ScrollToTop>
        </Router>
      </ToastProvider>
    </Container>
  )
}

//seul le style background est pris en compte...
export const MyCustomToast = ({ children, ...props }) => (
  <DefaultToast
    {...props}
    style={{
      width: '100vw !important',
      height: '120px !important',
      position: 'absolute !important',
      left: '0',
      zIndex: '999999999 !important'
    }}
  >
    <ToastContent>{children}</ToastContent>
  </DefaultToast>
)

export default App

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: clip;
`

const Content = styled.div`
  flex: 1 0 auto;
`

const ToastContent = styled.div``
