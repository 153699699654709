import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

import ContactForm from 'components/contactForm'

function Contact() {
  const rows = window.innerWidth <= 812 ? 6 : 12

  return (
    <React.Fragment>
      <TopSection>
        <Title>Contactez-nous</Title>
        <Center>
          <Intro>
            Vous avez une question ? Une recommandation ? Contactez-nous via le
            formulaire de contact ci-dessous et nous vous répondrons dans les
            plus brefs délais.
          </Intro>
        </Center>
      </TopSection>

      <Center>
        <Form>
          <FormContainer textAreaRows={rows} />
        </Form>
      </Center>
    </React.Fragment>
  )
}

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`

const TopSection = styled.div`
  padding-top: 60px;
  background: linear-gradient(0deg, #ffffff 0%, #f3fbfd 85.23%);
  text-align: center;
  padding-bottom: 200px;

  ${media.lessThan('1025px')`
    padding: 30px 0px 150px 0px;
  `}
`

const Title = styled.div`
  font-size: 39px;
  font-weight: 800;
  line-height: 0.87;
  padding-bottom: 30px;
  color: #1787e0;

  ${media.lessThan('1025px')`
    padding-bottom: 26px;
    font-size: 22px;
  `}
`

const FormContainer = styled(ContactForm)`
  flex-wrap: wrap;
  flex-direction: row;
  height: auto;
  width: 100%;
  max-width: 600px;
`

const Intro = styled.div`
  width: 80%;
  margin: auto;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.37;
  padding: 30px;
  color: #114b62;
  max-width: 500px;
  padding: 0 20px;

  ${media.lessThan('1025px')`
    width: 100%;
    font-size: 1.1em;
    font-weight: 600;

  `}
`

const Form = styled.div`
  position: relative;
  top: -150px;
  width: 100%;
  max-width: 600px;
  border-radius: 11px;
  box-shadow: 0px 2px 87px rgba(33, 146, 204, 0.1);
  background-color: #ffffff;
  text-align: center;
  padding: 45px;
  border-radius: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  ${media.lessThan('1025px')`
    top: -92px;
    width: 95%;
    height: 100%;
    padding: 20px;
    margin: 0px 3% 0px;
    flex-direction: column;
  `}

  ${media.greaterThan('1025px')`
    max-width: 640px;
    padding: 40px;
  `}
`

export default Contact
