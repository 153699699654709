export default {
  colors: {
    black: '#444',
    primary: '#1787E0',
    secondary: '#114b62',
    light_primary: '#F3F9FD',
    scarlet: '#E72E46',
    brownGrey: '#9e9e9E',
    grey: '#9e9e9E',
    lightGrey: '#cccaca',
    white: '#ffffff',
    buttonDisabled: '#dcdcdc',
    buttonDisabledText: '#808080'
  },
  fontWeight: {
    extraBold: 900,
    regular: 500,
    bold: 700,
    semiBold: 600,
    light: 200,
    extraLight: 100
  },
  sizing: {
    footerHeight: 75
  },
  margins: {
    x1: 8,
    x2: 16,
    x3: 24,
    x4: 32,
    x5: 48
  }
}
