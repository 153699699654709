import React from 'react'
import styled from 'styled-components/macro'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import media from 'styled-media-query'
import { extractUrlsFromMedias /*, getKanalImage*/ } from 'utils/image'

function Gallery({ images, isCard }) {
  if (!images) return null

  const urls = images ? extractUrlsFromMedias(images) : []

  const imagesFormatted = urls.map(url => ({
    original: url,
    loading: 'lazy'
  }))

  return (
    <SGallery>
      <ImageGallery
        showBullets
        showIndex
        autoPlay={false}
        showFullscreenButton={false}
        showThumbnails={false}
        showPlayButton={false}
        items={imagesFormatted}
        isCard={true}
        lazyLoad={true}
      />
    </SGallery>
  )
}

const SGallery = styled.div`
  width: 100%;
  max-width: 700px;
  z-index: 800;

  ${media.lessThan('1025px')`
    width: 100%;
  `}

  .image-gallery-content:not(.fullscreen) .image-gallery-image {
    width: calc(100% - 2px);
    height: 0;
    padding-top: 75%; /* Make the image wrapping div take a ratio of 3:4 */
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);

    img {
      position: absolute;
      top: 0;
      object-fit: cover;
      object-position: center center;
      width: 100%;
      height: 100%;
    }
    ${media.lessThan('1025px')`
      border: none;
      width: 100%;
    `}
  }

  .image-gallery-slides {
    border-radius: 20px;
    margin: 20px 0 50px 0;

    &.isCard {
      margin: 0 0 -40px 0;
    }

    ${media.lessThan('1025px')`
      margin: 0;
      border-radius: 0;
      box-shadow: none;
    `}
  }

  .image-gallery-index {
    top: initial;
    right: initial;
    left: 12px !important;
    bottom: 37px !important;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    font-size: 1em;
    font-family: NunitoSans;
    color: #ffffff;
    top: -97px;
    position: relative;
    width: 100%;
    max-width: 60px;
    text-align: center;

    ${media.lessThan('1025px')`
      bottom: 16px !important;
      font-size: 12px;
      top: -32px;
      max-width: 40px
      padding: 8px 10px;
  `}
  }

  .image-gallery-slide-wrapper .image-gallery-right-nav,
  .image-gallery-slide-wrapper .image-gallery-left-nav {
    top: 45%;
    opacity: 0.4;
  }

  .image-gallery-slide-wrapper .image-gallery-right-nav::before,
  .image-gallery-slide-wrapper .image-gallery-left-nav::before {
    text-shadow: 0 1px 13px rgba(0, 0, 0, 0.3) !important;
  }

  .image-gallery-slide,
  .image-gallery-image,
  .image-gallery-image .img {
    border-radius: 20px;
  }

  .image-gallery-bullets {
    bottom: 80px;
    ${media.lessThan('1025px')`
      bottom: 47px;
  `}
  }

  .image-gallery-bullets .image-gallery-bullets-container {
    margin-top: -30px;

    ${media.lessThan('1025px')`
      position: absolute;
      bottom: -9px;
      width: 100%;
  `}
  }
  .image-gallery-bullets .image-gallery-bullet {
    padding: 2px !important;
    opacity: 0.7;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
  .image-gallery-bullets .image-gallery-bullet.active {
    padding: 4px !important;
    opacity: 1 !important;
    background-color: #ffffff;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }
`

export default Gallery
