import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

import AndroidBadgeUrl3 from 'assets/androidBadge/android-badge-white@3x.png'
import AndroidBadgeUrl2 from 'assets/androidBadge/android-badge-white@2x.png'
import AndroidBadgeUrl from 'assets/androidBadge/android-badge-white.png'

import AndroidBadgeUrl3Black from 'assets/androidBadge/android-badge@3x.png'
import AndroidBadgeUrl2Black from 'assets/androidBadge/android-badge@2x.png'
import AndroidBadgeUrlBlack from 'assets/androidBadge/android-badge.png'

/*  */
function AndroidBadge({ black }) {
  return (
    <A
      href="https://play.google.com/store/apps/details?id=com.treedigitalfactory.paper.prod"
      target="_blank"
    >
      {black ? (
        <Img
          loading="lazy"
          src={AndroidBadgeUrlBlack}
          srcSet={`${AndroidBadgeUrl2Black} 2x, ${AndroidBadgeUrl3Black} 3x`}
          alt="android-badge"
        />
      ) : (
        <Img
          loading="lazy"
          src={AndroidBadgeUrl}
          srcSet={`${AndroidBadgeUrl2} 2x, ${AndroidBadgeUrl3} 3x`}
          alt="android-badge"
        />
      )}
    </A>
  )
}

const A = styled.a`
  display: flex;
  transition: all 0.2s ease;
  width: calc(100% - 20px);
  margin: 10px;
  &:hover {
    cursor: pointer;
    transform: translateY(-8px);
    transform: scale(1.1);
  }
`

const Img = styled.img`
  width: 180px;
  object-fit: contain;
  &:hover {
    cursor: pointer;
  }

  ${media.lessThan('1025px')`
    width:100%;
    height: auto;
    object-fit: contain;
    max-height: 60px;
    margin: 5px auto;
  `}
`

export default AndroidBadge
