import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import media from 'styled-media-query'
import { Animated } from 'react-animated-css'
import theme from 'utils/theme'

//import close from 'assets/broadcast/close-blue.svg'

import BG from 'assets/broadcast/bg-arlet-visibilite.jpg'
import BGMobile from 'assets/broadcast/bg-arlet-diffuser-mobile.jpg'

import Section1 from './Section1'
import Section2 from './Section2'
//import Section3 from './Section3'
//import Block from './Block'
//import AgencyForm from 'components/agencyForm'

function Broadcast() {
  //const [sticky, setSticky] = useState(true)
  //const [showForm, setShowForm] = useState(true)
  //const [footerPos, setFooterPos] = useState(true)
  //const isMobile = window.innerWidth <= 1025 ? true : false

  return (
    <div>
      <Hero>
        <TuilesSlider>
          <TitleContainer>
            <Title>
              Donnez <Blue>plus de visibilité</Blue> à vos annonces
              immobilières.
            </Title>
            <SubTitle>
              Vous êtes un professionnel de l'immobilier ? Développez tout le
              potentiel de vos annonces immobilières et trouvez des contacts
              rapidement.
            </SubTitle>
          </TitleContainer>
        </TuilesSlider>
        <Section1 />
        <Section2 />
      </Hero>
    </div>
  )
}

const Hero = styled.div`
  width: 100%;
  ${media.lessThan('1025px')`
  width: 100%;
`}
`
/*
const Overlay = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
  opacity: 1;
  height: 100vh;
  ${media.greaterThan('1025px')`
    display: none;
  `}
  &.show {
    visibility: hidden !important;
  }
  &.hide {
    visibility: visible !important;
    position: fixed;
    top: 0;
  }
`
*/
const TuilesSlider = styled.div`
  height: 70vh;
  //background: rgba(5, 163, 180, 1);
  background: #ffffff;

  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 -16px 17px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transform: translateZ(0);

  ${media.greaterThan('1025px')`
    max-height: 600px;
  `}
`

const TitleContainer = styled.div`
  width: 100vw;
  background-image: url(${BG});
  background-size: cover;
  height: 100%;
  position: absolute;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-self: center;

  ${media.lessThan('1025px')`
    padding: 5px;
    width: calc(100vw - 10px);
    background-image: url(${BGMobile});
  `}
`

const Title = styled.h1`
  font-size: 3em;
  font-weight: 900;
  line-height: 1;
  /* text-shadow: 0 2px 20px rgba(26, 67, 98, 0.59); */
  color: rgba(26, 67, 98, 1);
  /*background: rgba(255, 255, 255, 0.5); */
  padding: 20px;
  width: 100vw;
  text-align: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  opacity: 0.9;
  position: relative;
  width: calc(86% - 454px);
  text-align: left;
  left: 5%;
  max-width: 630px;

  ${media.lessThan('1025px')`
    font-size: 1.5em;
    line-height: 1em;
    width: calc(100% - 40px);
    text-align: center;
    left: 0%;
    top: 30vh;
    margin: auto;
  `}

  /* < iPhone X */
  @media (max-width: 1025px) and (max-height: 812px) {
    top: 15vh;
  }

  /* < iPhone 6 */
  @media (max-width: 1025px) and (max-height: 620px) {
    top: 5vh;
  }

  ${media.greaterThan('1025px')`
    top: 100px;
  `}
`

const SubTitle = styled.h2`
  font-size: 1em;
  font-weight: 600;
  line-height: 1;
  /* text-shadow: 0 2px 20px rgba(26, 67, 98, 0.59); */
  color: rgba(26, 67, 98, 1);
  /*background: rgba(255,255,255,.5);*/
  padding: 20px;
  width: 100vw;
  text-align: center;
  align-content: center;
  align-self: center;
  justify-content: center;
  justify-self: center;
  opacity: .9;
  position: relative;
  top: 100px;
  width: calc(86% - 454px);
  text-align: left;
  left: 5%;
  max-width: 630px;
  }
  ${media.lessThan('1025px')`
    font-size: 1.1em;
    line-height: 1.2em;
    padding-top: 0;
    width: calc(100% - 1em);
    text-align: center;
    left: 0%;
    margin: auto;
    margin-bottom: 15px;
    text-align: center;
    padding: 2em .5em;
    top: 30vh;
  `}

  /* < iPhone X */
  @media (max-width: 1025px) and (max-height: 812px) {
      top: 15vh;
   }

    /* < iPhone 6 */
    @media (max-width: 1025px) and (max-height: 620px) {
        top: 2vh;
     }

`

const Blue = styled.span`
  color: ${theme.colors.primary};
`

export default Broadcast
