import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { withRouter } from 'react-router-dom'
import media from 'styled-media-query'
import { capitalizeEachWord, capitalizeFirstLetterOnly } from 'utils/string'
import { HitType } from 'constants.js'

// import { useFetchData } from 'hooks/fetch.hooks'
import { getHitById, getProgramByProgramID } from 'services/hits'
import Gallery from 'pages/hitDetail/Gallery'
//import LikeButton from 'pages/hitDetail/LikeButton'
import ProgramTitle from 'pages/hitDetail/ProgramTitle'
import Title from 'pages/hitDetail/Title'
import Characteristics from 'pages/hitDetail/Characteristics'
//import Description from 'pages/hitDetail/Description'
//import Ref from 'pages/hitDetail/Ref'
//import AboutPrice from 'pages/hitDetail/AboutPrice'
//import Energy from 'pages/hitDetail/Energy'
//import InfoContact from 'pages/hitDetail/InfoContact'
//import ProgramLot from 'pages/hitDetail/ProgramLot'

// 32WC4YJ081LI9N86D83
// function DetailPaper({
//   match: {
//     params: { id }
//   }
// }) {

const Card = props => {
  const id = props.id

  const [isLoading, setIsLoading] = useState(true)
  const [hit, setHit] = useState(null)
  const [program, setProgram] = useState(null)

  useEffect(() => {
    console.log('id : ' + id)

    async function fetch(id) {
      let hit, program
      try {
        hit = await getHitById(id)
        setHit(hit)
      } catch (err) {
        console.error(err)
        setIsLoading(false)
        return
        // return Promise.reject(err)
      }

      if (hit && hit.T === 'typology' && hit.program_reference) {
        try {
          program = await getProgramByProgramID(hit.program_reference)
          setProgram(program)
        } catch (err) {
          console.error(err)
          setIsLoading(false)
          return
        }
      }

      setIsLoading(false)
    }
    fetch(id)
  }, [id])

  if (isLoading) return null

  if (!hit)
    return (
      <Container>
        <SDetailPaper>
          <CenterError>
            <SError>
              <ESImage
                src="/images/empty_state_ko.png"
                alt="Oops ! Une erreur est survenue"
              />
              <div>Oops ! L'annonce n'a pas pu être récupérée</div>
            </SError>
          </CenterError>
        </SDetailPaper>
      </Container>
    )
  /*
  const price = program
    ? Math.min(
        ...program.details.program.ads.map((lot: KontentProperty) => lot.price)
      )
    : hit?.tariffs?.PriceIncludingTax?.price
*/
  return (
    <Container>
      <SDetailPaper>
        <GalleryContainer>
          <Gallerie className="is-card" images={hit.medias} />
        </GalleryContainer>

        <Center>
          <SInfo>
            <GalleryContainer>{/*<LikeButton />*/}</GalleryContainer>
            {!!program && <ProgramTitle title={program.title} />}
            <Title
              title={`${capitalizeFirstLetterOnly(
                HitType[hit.type]
              )} à ${capitalizeEachWord(hit.location.city || '')}`}
              priceLabel={!!program ? `À partir de` : ''}
              price={
                hit.tariffs &&
                hit.tariffs.PriceIncludingTax &&
                hit.tariffs.PriceIncludingTax.price
              }
              category={hit.category}
            />

            <Characteristics isCard="true" hit={hit} program={program} />
          </SInfo>
        </Center>
      </SDetailPaper>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
`

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  max-width: 800px;
  width: 100%;
  margin: auto;
  margin-bottom: 6px;
  ${media.lessThan('1026px')`

  `}
  ${media.greaterThan('1025px')`
    position: relative;
    top: -50px;
  `}
`

const Gallerie = styled(Gallery)`

.image-gallery-image img {
  border-radius: 19px 19px 0 0;
}

.image-gallery-content:not(.fullscreen) .image-gallery-image {
  width: calc(100% - 2px);
  height: 0;
  padding-top: 75%; /* Make the image wrapping div take a ratio of 3:4 */
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 19px 19px 0 0;

  img {
    background: red;
    position: absolute;
    top: 0;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }

  .image-gallery-slide img {
    border-radius: 19px 19px 0 0;
  }

`

const CenterError = styled(Center)`
  ${media.lessThan('1026px')`
  min-height: calc(100vh - 260px);
  margin-bottom: 0px;
`}
`

const ESImage = styled.img`
  width: 200px;
  height: auto;
  margin: 0;
  margin-bottom: 30px;
  display: flex;
`

const GalleryContainer = styled.div``

const SDetailPaper = styled.div`
  flex: 1 0 auto;
  margin: 30px 0px;
  background: none;
  ${media.lessThan('1026px')`
    margin: 0px;
  `}
`

const SInfo = styled.div`
  position: relative;
  box-shadow: 0 2px 87px 0 rgba(85, 163, 181, 0.1);
  background-color: #ffffff;
  padding: 50px;
  width: 100%;
  box-sizing: border-box;
  z-index: 1;
  font-size: 20px;
  border-radius: 0 0 19px 19px;

  ${media.lessThan('1025px')`
    padding: 15px;
    width: 100%;
    margin-top: -43px;
    font-size: 16px;
  `}
`

const SError = styled(SInfo)`
  font-size: 30px;
  font-weight: 900;
  color: #114b62;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.lessThan('1025px')`
    font-size: 1em;
    padding: 15px;
    width: 100%;
    margin-top: 0px;
  `}
`

export default Card
