import { AsYouType, parsePhoneNumberFromString } from 'libphonenumber-js'

String.prototype.replaceAll = function(search, replacement) {
  var target = this
  return target.split(search).join(replacement)
}

export const getPhoneNumberFr = phoneStr => {
  let phoneNumberFr = phoneStr
    .replaceAll(' ', '')
    .replaceAll('.', '')
    .replaceAll(' ', '')
  if (phoneNumberFr.length === 10 && phoneNumberFr[0] === '0') {
    phoneNumberFr = '+33' + phoneNumberFr.substr(1)
    return new AsYouType('FR').input(phoneNumberFr)
  }
  return null
}

export const getPhoneNumber = phoneStr => {
  let isValid = false
  let country = null
  let phoneNumber = {}
  if (!phoneStr) {
    return null
  }
  let phoneStrFormatted = new AsYouType('FR').input(phoneStr)
  if (!phoneStrFormatted) {
    return null
  }
  const phoneNumberFr = getPhoneNumberFr(phoneStrFormatted)
  if (phoneNumberFr) {
    phoneStrFormatted = phoneNumberFr
  }
  const parsed = parsePhoneNumberFromString(phoneStrFormatted)
  if (parsed) {
    isValid = parsed.isValid()
    country = parsed.country
  }
  if (!isValid) {
    return null
  }
  phoneNumber.number = phoneStrFormatted
  phoneNumber.country = country
  return phoneNumber
}
