import axios from 'axios'

export const contactUs = async body => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/user/contact/`,
      body
    )
    return res.data
  } catch (err) {
    throw err
  }
}
