import React from 'react'
import styled, { css } from 'styled-components/macro'
import media from 'styled-media-query'
import IcnQuote from 'assets/home/icn-quote.png'

import theme from 'utils/theme'

function Testimonial(props) {
  return (
    <Center {...props}>
      <Img loading="lazy" src={IcnQuote} />
      <Stars src={'/images/' + props.stars + 'stars.png'} />
      <Text name="Jean Paul">
        {props.children}
        <TestimonialName>{props.name}</TestimonialName>
      </Text>
    </Center>
  )
}

const TestimonialName = styled.p`
  font-size: 1em;
  font-weight: bold;
  line-height: 1.17;
  margin-bottom: 0;
  color: ${theme.colors.secondary};
`

const Center = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 30px 20px 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #F3F9FD;
  border-radius: 15px;
  padding: 27px 30px 25px 30px;
  width: 25%;
  font-size: 0.6em;

  ${props =>
    props.padding &&
    css`
      padding: 0 5% 30px 5%;
    `};
  ${media.lessThan('768px')`
    position: relative;
      margin: 10px 20px 30px;
      width: 100%;
      top: 17em;
    `}
  ${media.between('769px', '1170px')`
      position: relative;
      margin: 10px 20px 30px;
      width: 100%;
      top: 0;
      max-width: 260px;
    `}
    ${media.greaterThan('1170px')`
        width: 30%;
      `}
`

const Img = styled.img`
  position: absolute;
  right: 20px;
  width: 66px;
  top: -19px;
  height: auto;
`

const Stars = styled.img`
  width: 100px;
  height: auto;
  object-fit: cover;
  align-self: baseline;
  margin-bottom: 5px;
`

const Text = styled.div`
  max-width: 600px;
  position: relative;
  font-size: 1em;
  text-align: left;
  color: ${theme.colors.secondary};
  z-index: 0;
  height: auto;
  display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin-top: 8px;

  ${media.lessThan('768px')`
    font-size: 1.7em;
    line-height: 1.2;
    padding: auto 20px;
    `}
  ${media.between('768px', '1170px')`
    font-size: 1.7em;
    line-height: 1.2;
    padding: auto 20px;
    `}
  ${media.greaterThan('1170px')`
    font-size: 1.7em;
    line-height: 1.2;
    padding: auto 20px;
      `}

`

export default Testimonial
