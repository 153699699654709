import React from 'react'
import styled from 'styled-components'
import media from 'styled-media-query'

function Icon({ name, color }) {
  if (!name) return null

  return <SIcon color={color} className={`icon-${name}`} />
}

const SIcon = styled.i`
  font-size: 24px;
  color: ${props => props.color || 'inherit'};

  ${media.lessThan('1025px')`
    font-size: 18px;
  `}
`

export default Icon
