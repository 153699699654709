import React from 'react'
import styled from 'styled-components/macro'
import media from 'styled-media-query'

function Section({ children }) {
  return <SSection>{children}</SSection>
}

const SSection = styled.div`
  margin-top: 50px;

  ${media.lessThan('1025px')`
    margin-top: 32px;
  `}
`

export default Section
