import React from 'react'
import styled from 'styled-components/macro'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps'
import media from 'styled-media-query'

import SectionName from './SectionName'

const Map = withScriptjs(
  withGoogleMap(({ lat, lng }) => {
    return (
      <GoogleMap defaultZoom={15} defaultCenter={{ lat, lng }}>
        <Marker position={{ lat, lng }} />
      </GoogleMap>
    )
  })
)

function Location({ lat, lng }) {
  return (
    <Center>
      <DesktopContainer>
        <SectionName>Localisation</SectionName>
      </DesktopContainer>
      <SLocation>
        <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: '40vh' }} />}
          mapElement={<div style={{ height: `100%` }} />}
          lat={lat}
          lng={lng}
        />
      </SLocation>
    </Center>
  )
}

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const SLocation = styled.div`
  width: 100%;
  max-height: 500px;
  border-radius: 20px;
  overflow: hidden;

  ${media.lessThan('1026px')`
    height: 40vh;
    margin-top: 20px;
  `}

  .gmnoprint .gm-bundled-control .gm-bundled-control-on-bottom {
    display: none !important;
  }
`

const DesktopContainer = styled.div`
  ${media.lessThan('1026px')`
    display: none;
  `}
`

export default Location
