import React from 'react'
import styled from 'styled-components/macro'
//import get from 'lodash/get'
import media from 'styled-media-query'
import theme from 'utils/theme'
import Icon from 'components/icon'
import Section from './Section'
import SectionName from './SectionName'
import groupBy from 'lodash/groupBy'
import { euro } from 'utils'
import Collapsible from 'react-collapsible'

function ProgramLotGroup({ group }) {
  const [visible, setVisible] = React.useState(true)

  const { rooms } = group[0].details

  const minPrice = Math.min(
    ...group.map(lot => lot.price || Number.MAX_SAFE_INTEGER)
  )
  const maxPrice = Math.max(
    ...group.map(lot => lot.price || Number.MIN_SAFE_INTEGER)
  )

  const toggleCollapsed = () => {
    setVisible(!visible)
  }

  const renderLot = (index: number, lot: ProgramProperty) => {
    return (
      <Lot
        key={`${lot.id}${lot.title}`}
        index={index}
        style={{ padding: `0px ${theme.margins.x3}` }}
      >
        <LotDescription>
          {`Appartement ${lot.details.rooms.total} pièce` +
            (lot.details.rooms.total === 1 ? '' : 's') +
            (lot.details.area ? `\n${lot.details.area} m²` : '')}
        </LotDescription>
        {lot.price && <LotPrice>{euro(lot.price)}</LotPrice>}
      </Lot>
    )
  }

  return (
    <LotGroup>
      <Collapsible
        open={true}
        trigger={
          <LotGroupHeader onClick={toggleCollapsed} activeOpacity={1}>
            <div>
              <LotGroupDescription>
                {`${group.length} appartement` +
                  (group.length === 1 ? '' : 's') +
                  ` - ${rooms.total} pièce` +
                  (rooms.total === 1 ? '' : 's')}
              </LotGroupDescription>
              <LotGroupText>
                {minPrice !== maxPrice
                  ? `De ${euro(minPrice)} à ${euro(maxPrice)}`
                  : `${euro(minPrice)}`}
              </LotGroupText>
            </div>
            <Icon name={visible ? 'angle-up-1' : 'angle-down-1'} size={25} />
          </LotGroupHeader>
        }
      >
        {group.map((lot, index) => renderLot(index, lot))}
      </Collapsible>
    </LotGroup>
  )
}

function ProgramLot({ program }) {
  const nbLots = program.details.program.ads.length
  const groups = groupBy(
    program.details.program.ads,
    (lot: ProgramProperty) => {
      return lot.details.rooms.total
    }
  )

  return (
    <Section>
      <SectionName>
        {nbLots > 1 ? `${nbLots} lots disponibles` : '1 lot disponible'}
      </SectionName>
      {Object.values(groups).map(group => (
        <ProgramLotGroup key={group[0].id} group={group} />
      ))}
    </Section>
  )
}

const LotGroup = styled.div`
  margin: 0px -50px;
`

const LotGroupHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.colors.primary};
  padding: 15px 50px;
  margin: ${theme.margins.x1}px 0px;
  cursor: pointer;
`

const LotGroupDescription = styled.div`
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.white};
  font-size: 1em;
`

const LotGroupText = styled.div`
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.white};
  font-size: 1em;
`

// const Collapsible = styled.div`
//   height: 100%;
//   overflow: hidden;
//   max-height: ${props => props.visible ? '1000px' : '0px'};
//   transition: max-height .5s;
// `

const Lot = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 50px;
  border-top: ${props =>
    props.index > 0 ? `1px solid ${theme.colors.lightGrey}` : `none`};
  ${media.lessThan('1025px')`
    padding: 10px 50px;
  `}
`

const LotPrice = styled.div`
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.colors.secondary};
  font-size: 1em;
`

const LotDescription = styled.div`
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.colors.secondary};
  font-size: 1em;
  line-height: 28px;
`

export default ProgramLot
